import React from 'react';
import * as Services from '../utility/services';
import {checkIfEmpty} from '../utility/functions';
const uuidv1 = require('uuid/v1');

const UserTypes = require('../utility/userTypes.json');

export default class Modal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ip: "",
            port: "",
            username: "",
            pasword: "",
            companyKey: "",
            //id: null
        }
    }

    onAccept = () => {
        if(this.props.user.type === UserTypes.admin_company){
            let MQTT = {
                //id: this.state.id,
                ip: this.state.ip,
                port: this.state.port, 
                username: this.state.username,
                password: this.state.pasword
            }
            if(checkIfEmpty(MQTT).length == 0 && this.props.companyKey !== ""){
                Services.Pending(this.props.user._id, true, MQTT, this.state.companyKey).then(this.props.refresh).catch(err => {if(err.status === 400) alert("Id already exists")});
            }
            else
                alert("Please fill out all the fields");
        } 
        else {
            Services.Pending(this.props.user._id, true, null, null).then(this.props.refresh);
        }
    }

    onDecline = () => {
        Services.Pending(this.props.user._id, false).then(this.props.refresh);
    }

    onDelete = () => {
        Services.DeleteUser(this.props.user._id).then(this.props.refresh).catch(err => console.log(err));
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    objectDetails = (object) => {
        let array = [];

        for(let key in object) {
            if(Object.prototype.hasOwnProperty.call(object, key)){
                let value = object[key];
                if(typeof value !== "object")
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value + " "}</div>)
                else
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{this.objectDetails(value)}</div>)
            }
        }
        return array;
    }

    getButtons = (type) => {
        if(type === "pending"){
            return (
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger"  data-dismiss="modal" onClick={this.onDecline}>Decline</button>
                    <button type="button" className="btn btn-success"  data-dismiss="modal" onClick={this.onAccept}>Accept</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            );
        }
        else if(type === "rejected"){
            return (
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger"  data-dismiss="modal" onClick={this.onDelete}>Delete</button>
                    <button type="button" className="btn btn-success"  data-dismiss="modal" onClick={this.onAccept}>Accept</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            );
        }
        else{
            return(
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {((this.props.user ? (this.props.user.type === 1 ? true : false) : false) && (this.props.type === "pending" || this.props.type === "rejected"))
                                ?
                                    <div>
                                        <label>MQTT</label>
                                        {/*<div className="input-group mb-3">
                                            <input className="form-control" name="id" placeholder="Id" value={this.state.id} onChange={this.onChange}></input>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" id="mqttId_generator" onClick={() => this.setState({id: uuidv1()})}>Generate ID</button>
                                            </div>
                                        </div>*/}
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input className="form-control" name="ip" placeholder="IP" value={this.state.ip} onChange={this.onChange}></input>
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" name="port" placeholder="Port" value={this.state.port} onChange={this.onChange}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input className="form-control" name="username" placeholder="Username" value={this.state.username} onChange={this.onChange}></input>
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form-control" name="pasword" placeholder="Password" value={this.state.pasword} onChange={this.onChange}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="companyKey">Company KEY for Sign Up</label>
                                            <input className="form-control" name="companyKey" value={this.state.companyKey} onChange={this.onChange}></input>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                            {this.objectDetails(this.props.user)}
                        </div>
                        {this.getButtons(this.props.type)}
                    </div>
                </div>
            </div>
        );
    }
}