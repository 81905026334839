import React from 'react';
import * as Services from '../../utility/services';

const topicExample = {
    tags: [],
    properties: [{ text: "", type: "String/Integer/Double/Boolean" }],
    topic: "",
    level: "",
    description: ""
}

const topicCompanyExaple = {
    company: "companyID",
    topic: "topicID",
    type: "subscribe/publish"
}

const adminExaple = {
    username: "",
    firstName: "",
    lastName: "",
    password: ""
}

export default class Import extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            inputFile: null,
            context: null,
            importType: null,
            template: null,
            preview: null,
            loading: false,
            importing: false,
            responses: null,
            rowsPerPage: 10,
            page: 0
        }
        this.reader = new FileReader();
    }

    componentDidMount() {
        this.reader.onload = (e) => {
            console.log(JSON.parse(e.target.result));
            this.setState({context: JSON.parse(e.target.result)});
        }
    }

    onImportTypeChange = (e) => {
        const value = e.target.value;
        let json = null;
        switch (value) {
            case "topic":
                json = JSON.stringify(topicExample, null, 4);
                break;
            case "topic_company":
                json = JSON.stringify(topicCompanyExaple, null, 4);
                break;
            case "admin":
                json = JSON.stringify(adminExaple, null, 4);
                break;
            default:
                json = null;
                break;
        }
        this.setState({importType: e.target.value, template: (<pre><code>Example: <br/>[{json}]</code></pre>)})
    }

    /*getPreview = (context) => {
        return <pre><code>{JSON.stringify(context, null, 4)}</code></pre>;
    }*/

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.files}); 
    }

    onLoadFile = () => {
        if(this.state.inputFile)
            if(this.state.inputFile.length)
                this.reader.readAsText(this.state.inputFile[0]);
    }

    onImport = () => {
        switch (this.state.importType) {
            case "topic":
                this.state.context.forEach((topic, index) => {
                    Services.AddTopic(topic).then(res => console.log(res)).catch(err => console.log(err));
                });
                break;
            case "topic_company":
                this.state.context.forEach((topic_company, index) => {
                    Services.AddTopicCompany(topic_company).then(res => console.log(res)).catch(err => console.log(err));
                });
                break;
            default:
                break;
        }
    }

    objectDetails = (object) => {
        let array = [];

        for(let key in object) {
            if(Object.prototype.hasOwnProperty.call(object, key)){
                let value = object[key];
                if(typeof value !== "object")
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value + " "}</div>)
                else
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{this.objectDetails(value)}</div>)
            }
        }
        return array;
    }

    getTable = (data, headerData, properties) => {
        let table = [];
        let body = [];
        let row = [];
        let header = [];
        
        for(let i=0; i<headerData.length; i++){
            if(headerData[i] !== "")
                header.push(<th key={i} scope="col">{headerData[i]}</th>);
            else
                header.push(<th key={i} className="actions" scope="col">{headerData[i]}</th>);
        }

        table.push(
            <thead key={"thead"}>
                <tr>{header}</tr>
            </thead>
        );

        data.forEach((object, index) => {
            properties.forEach((property) => {
                if(typeof object[property] !== "object")
                    row.push(<td key={property + index}>{object[property] + " "}</td>);
                else
                    row.push(<td key={property + index}>{this.objectDetails(object[property])}</td>);
            });
            body.push(<tr key={"row" + index}>{row}</tr>);
            row = [];
        });

        table.push(<tbody key={"body"}>{body}</tbody>);
        return <table className="table">{table}</table>;
    }

    getPreview = () => {
        switch (this.state.importType) {
            case "topic":
                return this.getTable(this.state.context, ["Topic", "description", "Level", "Tags", "Properties"], ["topic", "description", "level", "tags", "properties"]);
            case "topic_company":
                return this.getTable(this.state.context, ["Topic", "Company", "Rights"], ["topic", "company", "type"]);
            default:
                break;
        }
    }

    render(){
        return(
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Import</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group">
                            <label htmlFor="importType">Import type</label>
                            <select className="form-control" id="importType" onChange={this.onImportTypeChange}>
                                <option value=""></option>
                                <option value="topic">Topic</option>
                                <option value="topic_company">Topic - Company</option>
                            </select>
                        </div>
                        <div>
                            {this.state.template}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFile">File input</label>
                            <input type="file" accept=".json" className="form-control-file" id="inputFile" onChange={this.onChange} />
                        </div>
                        <button className="btn btn-primary btn-block" onClick={this.onLoadFile}>Load</button>
                        { this.state.context
                            ?
                                <div>
                                    <hr/>
                                    <h3>Preview</h3>
                                    <button className="btn btn-block btn-primary" onClick={this.onImport}>Import</button>
                                    {this.getPreview()}
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}