import React from 'react';
import '../App.css';
import * as Services from '../utility/services';
import {checkIfEmpty, breakUniqueMongoDBError} from '../utility/functions';

export default  class SignUp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            username: "",
            office: "",
            phone: "",
            email: "",
            password: "",
            repeatPassword: "",
            alert: false,
            //companies: [],
            newCompany: false,
            //selectCompany: -1,
            companyName: "",
            companyAddress: "",
            companyPhone: "",
            companyEmail: "",
            companyKey: null,
            loading: false,
            terms_conditions: false
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onLogin = () => {
        this.props.history.push("/login");
    }

    onSignUp = () => {
        this.setState({loading: true});
        let newUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            password: this.state.password,
            companyID: this.state.companyKey,
            phone: this.state.phone,
            email: this.state.email,
            type: this.state.newCompany ? 1 : 0,
            accepted: 0
        }

        let errorString = "";
        let company = null;
        if(this.state.newCompany){
            company = {
                name: this.state.companyName,
                address: this.state.companyAddress,
                phone: this.state.companyPhone,
                email: this.state.companyEmail,
                accepted: 0
            }
            if(checkIfEmpty(company).length != 0)
                errorString += "Please fill out all fields. \n"
        }
        /*else {
            if(this.state.selectCompany === -1)
                errorString += "Please select company. \n"
            company = this.state.companies[this.state.selectCompany];
        }*/

        if(checkIfEmpty(newUser).length != 0 && errorString === "")
            errorString += "Please fill out all fields. \n"
        else if(this.state.repeatPassword === "" && errorString === "")
            errorString += "Please fill out all fields. \n"

        if(this.state.password !== this.state.repeatPassword && errorString === "")
            errorString += "Password and repeat password don't match.\n"

        if(!this.state.terms_conditions)
            errorString += "Need to agree to Terms and Conditions. \n"

        if(errorString !== "")
            this.setState({alert: errorString, loading: false});
        else {
            if(this.state.newCompany){
                Services.SignUp(newUser, company)
                    .then(response => { 
                        this.props.history.push('/login');
                    })
                    .catch(err => {
                        let msg = breakUniqueMongoDBError(err.response.data);
                        this.setState({alert: msg !== "" ? msg : err.response.data, loading: false});
                    });
                /*
                Services.SignUpCompany(company).then(res => {
                    newUser.companyID = res._id;
                    
                }).catch(err => {
                    this.setState({alert: err.response.data});
                });
                */
            }
            else {
                //newUser.companyID = this.state.companyKey;
                Services.SignUp(newUser)
                    .then(response => { 
                        this.props.history.push('/login');
                    })
                    .catch(err => {
                        this.setState({alert: err.response.data, loading: false});
                    });
/*
                Services.GetCompanyByKey(this.state.companyKey)
                    .then(res => {
                        newUser.companyID = res._id;
                        //newUser.company = res;
                        
                    })
                    .catch(err => console.log(err))*/
            }
        }
    }

    render() {
        return (
            <div className="App">
                <div>
                    <div className="LoginLogo">
                        {window.REACT_APP_TITLE == "UMPlatforma" ?
                            <h2><b className='blue'>UM</b>Platforma</h2>
                        :
                            <h2><b>{window.REACT_APP_TITLE}</b></h2>
                        }
                    </div>
                    <div className="LoginForm">
                        {this.state.alert !== false
                            ? 
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {this.state.alert}
                                </div>
                            :
                                null
                        }
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <input id="inputFirstName" className="form-control" name="firstName" placeholder="First name" onFocus={this.onChange} onChange={this.onChange}></input>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <input id="inputLastName" className="form-control" name="lastName" placeholder="Last name" onFocus={this.onChange} onChange={this.onChange}></input>
                                </div>
                            </div>
                        </div>
                        <div className="form-group"  style={{width: "100%"}}>
                            <input id="inputUsername" className="form-control" name="username" placeholder="Username" onFocus={this.onChange} onChange={this.onChange}></input>
                        </div>
                        {/*<div className="form-group"  style={{width: "100%"}}>
                            <input id="inputCompany" className="form-control" name="company" placeholder="Company" onChange={this.onChange}></input>
                            
                            <label htmlFor="selectCompany">Select company</label>
                            <select className="form-control" id="selectCompany" value={this.state.value} onChange={this.selectedCompany}>
                                {this.state.companies.map((topic, index) => {
                                    return <option key={index}>{topic}</option>
                                })}
                            </select>
                            <button type="button" className="btn btn-link float-right" onClick={() => this.setState({newCompany: true})}>New company</button>
                        </div>*/}
                        <div className="form-group"  style={{width: "100%"}}>
                            <input id="inputPhone" className="form-control" name="phone" placeholder="Phone" onFocus={this.onChange} onChange={this.onChange}></input>
                        </div>
                        <div className="form-group"  style={{width: "100%"}}>
                            <input id="inputEmail" className="form-control" name="email" placeholder="E-mail" onFocus={this.onChange} onChange={this.onChange}></input>
                        </div>
                        <div className="form-group" style={{width: "100%"}}>
                            <input id="inputPassword" className="form-control form-control" name="password" placeholder="Password" onFocus={this.onChange} onChange={this.onChange} type="password"></input>
                        </div>
                        <div className="form-group" style={{width: "100%"}}>
                            <input id="inputRepeatPassword" className="form-control form-control" name="repeatPassword" placeholder="Repeat password" onFocus={this.onChange} onChange={this.onChange} type="password"></input>
                        </div>
                        {this.state.newCompany 
                            ?   
                                <div className="form-group"  style={{width: "100%"}}>
                                    <label htmlFor="companyName">Company info</label>
                                    <div className="form-group"  style={{width: "100%"}}>
                                        <input className="form-control" name="companyName" placeholder="Name" onFocus={this.onChange} onChange={this.onChange}></input>
                                    </div>
                                    <div className="form-group"  style={{width: "100%"}}>
                                        <input className="form-control" name="companyAddress" placeholder="Address" onFocus={this.onChange} onChange={this.onChange}></input>
                                    </div>
                                    <div className="form-group"  style={{width: "100%"}}>
                                        <input className="form-control" name="companyEmail" placeholder="Email" onFocus={this.onChange} onChange={this.onChange}></input>
                                    </div>
                                    <div className="form-group"  style={{width: "100%"}}>
                                        <input className="form-control" name="companyPhone" placeholder="Phone" onFocus={this.onChange} onChange={this.onChange}></input>
                                        <button type="button" className="btn btn-link float-right" onFocus={() => this.setState({newCompany: false})} onClick={() => this.setState({newCompany: false})}>Existing company</button>
                                    </div>
                                </div>
                            :
                                <div className="form-group"  style={{width: "100%"}}>
                                    {/*<select className="form-control" id="selectCompany" name="selectCompany" value={this.state.value} onChange={this.onChange}>
                                        {this.state.companies.map((company, index) => {
                                            return <option key={company._id} value={index}>{company.name}</option>
                                        })}
                                    </select>*/}
                                    <input className="form-control" name="companyKey" placeholder="Company KEY" onFocus={this.onChange} onChange={this.onChange}></input>
                                    <button type="button" className="btn btn-link float-right" onFocus={() => this.setState({newCompany: true})} onClick={() => this.setState({newCompany: true})}>New company</button>
                                </div>
                        }
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="terms_conditions" onClick={() => this.setState({terms_conditions: !this.state.terms_conditions})}/>
                            <label className="form-check-label" htmlFor="terms_conditions">
                                I accept the <a href="/terms_conditions">Terms and Conditions</a>
                            </label>
                        </div>
                        <button type="button" className="btn btn-primary btn-block" disabled={this.state.loading} onClick={this.onSignUp}>
                            {this.state.loading && <span className="spinner-border spinner-border-sm spinnerStyle" role="status" aria-hidden="true"></span>}
                            SIGN UP
                        </button>
                        <hr></hr>
                        Already have an account?
                        <button type="button" className="btn btn-outline-primary btn-block" onClick={this.onLogin}>SIGN IN</button>
                    </div>
                </div>
            </div>
        );
    }
}

