import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as Services from '../utility/services';

class ForgotPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            emailUsername: "",
            alertErr: null,
            alertRes: null
        }
    }

    componentDidMount() {
        /*this.props.logIn("admin1", "SC_kaktus")
            .then(res => res)
            .catch(err => err)*/
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onResetPassword = () => {
        Services.ForgotPassword(this.state.emailUsername)
            .then(res => {
                if(res){
                    if(res.status === 200)
                        this.setState({alertRes: res.data, alertErr: null});
                    else
                        console.log(res);
                }
            })
            .catch(err => {
                if(err.response){
                    if(err.response.status === 400)
                        this.setState({alertErr: err.response.data, alertRes: null});
                    else
                        console.log(err.response);
                }
                else
                    console.log(err)
            })
    }

    onGoBack = () => {
        this.props.history.push("/login");
    }

    render() {
        return (
            <div className="App">
                <div>
                    <div className="LoginLogo">
                        {window.REACT_APP_TITLE == "UMPlatforma" ?
                            <h2><b className='blue'>UM</b>Platforma</h2>
                        :
                            <h2><b>{window.REACT_APP_TITLE}</b></h2>
                        }
                    </div>
                    <div className="LoginForm">
                        {this.state.alertErr
                            ? 
                                <div className="alert alert-danger fade show" role="alert" style={{width: "100%"}}>
                                    {this.state.alertErr}
                                </div>
                            :
                                null
                        }
                        {this.state.alertRes
                            ? 
                                <div className="alert alert-success fade show" role="alert" style={{width: "100%"}}>
                                    {this.state.alertRes}
                                </div>
                            :
                                null
                        }
                        <div className="form-group" style={{width: "100%"}}>
                            <input id="inputEmailUsername" className="form-control" name="emailUsername" placeholder="Email or username" onChange={this.onChange}></input>
                        </div>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onResetPassword}>RESET PASSWORD</button>
                        <hr></hr>
                        <button type="button" className="btn btn-outline-primary btn-block" onClick={this.onGoBack}>Go back to login</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;