import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as Services from '../utility/services';

class ResetPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            alertErr: null,
            alertRes: null
        }
    }

    componentDidMount() {
        /*this.props.logIn("admin1", "SC_kaktus")
            .then(res => res)
            .catch(err => err)*/
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onResetPassword = () => {
        if(this.state.password === "" || this.state.confirmPassword === ""){
            this.setState({alertErr: "Please fill out all the fields", alertRes: null});
        }
        else if(this.state.password !== this.state.confirmPassword){
            this.setState({alertErr: "New password and confirm password don't match.", alertRes: null});
        }
        else{
            let url = window.location.href;
            let token = url.split('/').pop();
            Services.ResetPassword(token, this.state.password)
                .then(res => {
                    this.props.history.push("/login");
                })
                .catch(err => {
                    if(err.response === undefined)
                        this.setState({alertErr: "Something went wrong"});
                    else
                        this.setState({alertErr: err.response.data});
                })
        }
    }

    onGoBack = () => {
        this.props.history.push("/login");
    }

    render() {
        return (
            <div className="App">
                <div>
                    <div className="LoginLogo">
                        {window.REACT_APP_TITLE == "UMPlatforma" ?
                            <h2><b className='blue'>UM</b>Platforma</h2>
                        :
                            <h2><b>{window.REACT_APP_TITLE}</b></h2>
                        }
                    </div>
                    <div className="LoginForm">
                        {this.state.alertErr
                            ? 
                                <div className="alert alert-danger fade show" role="alert" style={{width: "100%"}}>
                                    {this.state.alertErr}
                                </div>
                            :
                                null
                        }
                        {this.state.alertRes
                            ? 
                                <div className="alert alert-success fade show" role="alert" style={{width: "100%"}}>
                                    {this.state.alertRes}
                                </div>
                            :
                                null
                        }
                        <div className="form-group" style={{width: "100%"}}>
                            <input id="inputPassword" className="form-control" name="password" placeholder="New password" onChange={this.onChange} type="password"></input>
                        </div>
                        <div className="form-group" style={{width: "100%"}}>
                            <input id="inputConfirmPassword" className="form-control" name="confirmPassword" placeholder="Confirm password" onChange={this.onChange} type="password"></input>
                        </div>
                        <button type="button" className="btn btn-primary btn-block" onClick={this.onResetPassword}>UPDATE PASSWORD</button>
                        <hr></hr>
                        <button type="button" className="btn btn-outline-primary btn-block" onClick={this.onGoBack}>Go back to login</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;