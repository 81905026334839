import React from 'react';
import '../App.css';
import {getLocalISOString} from '../utility/functions';
import * as Services from '../utility/services';

const tagSeperator = ";"

const properties = {
    topicIndex: -1,
    description: "",
    tags: "",
    latitude: "",
    longitude: "",
    radius: "",
    street: "",
    city: "",
    country: "",
    end: ""
}

class MultipleEditModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            index: 0,
            tagsOption: "replace",
            descriptionOption: "replace",
            ... properties
        }
        this.update = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.topicIndex !== this.state.topicIndex && this.state.topicIndex == -1) {
            this.setState({topicIndex: prevProps.topicIndex});
        }
    }

    getOptions = (type) => {
        const options = ['replace', 'append', 'remove']
        let result = []
        options.forEach(option => {
            result.push(
                <div className="form-check form-check-inline" key={"optionsFor" + type + option}>
                    <input className="form-check-input" key={"radio" + type + option} type="radio" name={type+"Option"} id={type + option} value={option} checked={this.state[type] === option} onChange={(e) => this.setState({[type]: e.target.value})}/>
                    <label className="form-check-label" key={"label" + type + option} htmlFor={type + option}>{option}</label>
                </div>
            )
        })
        return <span style={{float: "right"}}>{result}</span>
    }

    onChange = () => {
        let changes = {
            device_ids: this.props.selected,
            update: [],
            tagsOption: this.state.tagsOption,
            descriptionOption: this.state.descriptionOption
        }
        Object.keys(properties).forEach(key => {
            if(key == "topicIndex" && this.state.topicIndex != this.props.topicIndex){
                changes.update.push({topic_id: this.props.topics[this.props.topicIndex]._id})
            }
            else if(this.state[key] != ""){
                if(key == "tags")
                    changes.update.push({[key]: this.state[key].split(tagSeperator)})
                else
                    changes.update.push({[key]: this.state[key]})
            }
        })
        if(changes.update.length == 0){
            window.alert("Nothing was changed")
        }
        else{
            Services.EditMultipleDevices(changes).then(res => {
                this.props.refresh()
                alert("Successful multiple update")
            }).catch(err => {
                alert("Something went wrong")
            })
        }
    }

    render() {
        return(
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalTitle">Multiple Device Edit</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="form-group">
                                <label htmlFor="selectTopic">Select topic</label>
                                <select className="form-control" id="selectTopic" value={this.state.topicIndex} onChange={(e) => this.setState({topicIndex: e.target.value})}>
                                    {this.props.topics.map((topic, index) => {
                                        return <option key={index} value={index}>{topic.topic}</option>
                                    })}
                                </select>
                            </div>
                            <div className="form-group" key="tags">
                                <label htmlFor="inputTags">Tags (seperated with {tagSeperator} )</label>
                                {this.getOptions("tagsOption")}
                                <input className="form-control" type="text" id="inputTags" value={this.state.tags} onChange={(e) => this.setState({tags: e.target.value})}></input>
                            </div>
                            <div className="form-group" key="description">
                                <label htmlFor="inputDescription">Description</label>
                                {this.getOptions("descriptionOption")}
                                <textarea className="form-control" type="text" id="inputDescription" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})} ></textarea>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm">
                                    <label htmlFor="inputLat">Latitude</label>
                                    <input className="form-control" type="number" id="inputLat" value={this.state.latitude} onChange={(e) => this.setState({latitude: e.target.value})} required></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputLgt">Longitude</label>
                                    <input className="form-control" type="number" id="inputLgt" value={this.state.longitude} onChange={(e) => this.setState({longitude: e.target.value})} required></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputLgt">Radius (optional)</label>
                                    <input className="form-control" type="number" id="inputRadius" value={this.state.radius} onChange={(e) => this.setState({radius: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm">
                                    <label htmlFor="inputStreetAddress">Street Address</label>
                                    <input className="form-control" type="text" id="inputStreetAddress" value={this.state.street} onChange={(e) => this.setState({street: e.target.value})} required></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputCity">City</label>
                                    <input className="form-control" type="text" id="inputCity" value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} required></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputCountry">Country</label>
                                    <input className="form-control" type="text" id="inputCountry" value={this.state.country} onChange={(e) => this.setState({country: e.target.value})} required></input>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="datetimeEnd">End</label>
                                <input className="form-control" type="datetime-local" id="datetimeEnd" min={getLocalISOString(new Date())} value={this.state.end ? this.state.end : ""} onChange={(e) => this.setState({end: e.target.value})}></input>
                            </div>           
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.onChange}>Change</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MultipleEditModal;