import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {setMarkerLocation, refreshMap} from '../redux/actions';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/fontawesome-free-5.11.2-web/css/all.css';

/*const LeafletIcon2 = L.icon({
    iconUrl: icon,
    shadowUrl: shadow,
    iconSize:     [25, 41], // size of the icon
    shadowSize:   [41, 41], // size of the shadow
    iconAnchor:   [12, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [12, 41],  // the same for the shadow
    popupAnchor:  [0, -41] // point from which the popup should open relative to the iconAnchor
});*/

const iconHtmlStyle = `
  color: #fff;
  transform: rotate(-45deg);
  margin: auto;`


function getHtmlPin(color, faClass){
    let markerStyle = `
    background-color: ${color};
    width: 30px;
    height: 30px;
    display: block;
    left: -15px;
    top: -15px;
    position: relative;
    border-radius: 30px 30px 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF;
    display: flex;`
    return `<span style="${markerStyle}" /><i class="${faClass}" style="${iconHtmlStyle}"></i>`;
}

const LeafletIconClass = L.DivIcon.extend({
    options: {
        className: "my-custom-pin",
        iconSize: [30, 30],
        iconAnchor: [0, 22],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
    }
});

//const LeafletIcon = new LeafletIconClass({html: getHtmlPin("#777", "fab fa-accessible-icon")});
//let LeafletIconSelection = [];
//LeafletIconSelection["#777fab fa-accessible-icon"] = LeafletIcon;

class Map extends React.Component {
    constructor(props){
        super(props);
        this.marker = undefined;
        this.circle = undefined;
        this.move = true;
        this.markers = [];
        this.multipleInit = false;
        this.leafletIconSelection = [];
        this.layers = [];
        this.state = {
            mapStyle: {}
        }
    }

    async componentDidUpdate() {
        if(!this.props.multiple){
            await this.checkStyles([this.props.topic]);
            if(this.marker)
                this.map.removeLayer(this.marker);
            if(this.circle)
                this.map.removeLayer(this.circle);
            if(this.props.markerLocation.latitude !== "" && this.props.markerLocation.longitude !== "")
                this.addMarker(this.props.markerLocation.latitude, this.props.markerLocation.longitude, this.props.markerLocation.radius, this.props.topic.id);

            if(this.props.marker){
                if(this.marker)
                    this.map.removeLayer(this.marker);
                if(this.circle)
                    this.map.removeLayer(this.circle);
                this.addMarker(this.props.latitude, this.props.longitude, this.props.radius, this.props.topic.id);
            }
        }
        else if(this.multipleInit === false){
            let filter_mapMarkerStyle = this.props.multiple.map(device => ({mapMarkerStyle: device.topic.mapMarkerStyle, id: device.topic._id, topic: device.topic.topic})).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);
            await this.checkStyles(filter_mapMarkerStyle);

            this.multipleInit = true;
            for(let i=0; i<this.props.multiple.length; i++){
                let device = this.props.multiple[i];
                this.addMarker(device.latitude, device.longitude, device.radius, device.topic._id, `<p>${device.title}</p>`);
                this.layers[device.topic._id].markers.push(this.marker);
            }

            let topicLayers = {};

            for (let key in this.layers) {
                let test = L.layerGroup(this.layers[key].markers)
                topicLayers[(this.layers[key].topic.mapMarkerStyle ? '<i class="'+ this.layers[key].topic.mapMarkerStyle.faClass +'" style="color: '+this.layers[key].topic.mapMarkerStyle.color+'"></i> ' : "") + this.layers[key].topic.topic] = test;
                test.addTo(this.map);
            }
            
            L.control.layers({},topicLayers).addTo(this.map);
        }
    }

    async componentDidMount() {
        this.map = L.map('map', {
            center: [this.props.latitude, this.props.longitude],
            zoom: this.props.zoom,
            //scrollWheelZoom: false
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 20,
            attribution: '<a href="http://www.justicemap.org/terms.php">Justice Map</a>',
        }).addTo(this.map);

        let mapThis = this;

        L.Control.Full = L.Control.extend({
            onAdd: function(map){
                let button = L.DomUtil.create('button');
                button.style.width = '34px';
                button.style.height = '34px';
                button.style.border = "2px solid rgba(0,0,0,0.3)";
                button.style.backgroundColor = 'white';
                button.style.borderRadius = "4px";
                //button.style.display = "flex";
                //button.style.justifyContent = "center";

                let fullScreenIcon = '<i class="fas fa-expand"></i>'// '<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path></svg>';
                let fullScreenExitIcon = '<i class="fas fa-compress"></i>' //'<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"></path></svg>';
                button.innerHTML = fullScreenIcon;

                button.onpointerdown = function(){
                    if(button.innerHTML === fullScreenIcon){
                        mapThis.setState({mapStyle: {
                            position: "fixed",
                            top: "72px",
                            left: "0px",
                            height: "calc(100vh - 72px)",
                            margin: "0px",
                            zIndex: "100"
                        }},
                            function(){ 
                                map.invalidateSize();
                                button.innerHTML = fullScreenExitIcon;
                            }
                        )
                    }
                    else{
                        mapThis.setState({
                            mapStyle: {position: "relative"}}, 
                            function(){ 
                                button.innerHTML = fullScreenIcon;
                                map.invalidateSize(); 
                                mapThis.scrollToBottom(); 
                        });
                    }
                }
                
                button.onmouseover = function(){
                    button.style.backgroundColor = '#f4f4f4'; 
                }
                button.onmouseout = function(){
                    button.style.backgroundColor = 'white'; 
                }

                return button;
            },
            onRemove: function(map){
                
            }
        })

        L.control.full = function(opts) {
            return new L.Control.Full(opts);
        }
        
        L.control.full({ position: 'topleft' }).addTo(this.map);

        if(this.props.multiple){
            let filter_mapMarkerStyle = this.props.multiple.map(device => ({mapMarkerStyle: device.topic.mapMarkerStyle, id: device.topic._id, topic: device.topic.topic})).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);
            await this.checkStyles(filter_mapMarkerStyle);
            if(this.props.multiple.length)
                this.multipleInit = true;

            this.props.multiple.forEach(device => {
                this.addMarker(device.latitude, device.longitude, device.radius, device.topic._id, `<p>${device.title}</p>`);
            });
        }
        else if(this.props.marker){
            await this.checkStyles([this.props.topic]);
            if(this.marker)
                this.map.removeLayer(this.marker);
            if(this.circle)
                this.map.removeLayer(this.circle);
            this.addMarker(this.props.latitude, this.props.longitude, this.props.radius, this.props.topic.id);
        }
        else {
            if(this.props.markerLocation.latitude !== "" && this.props.markerLocation.longitude !== ""){
                await this.checkStyles([this.props.topic]);
                if(this.marker)
                    this.map.removeLayer(this.marker);
                if(this.circle)
                    this.map.removeLayer(this.circle);
                this.addMarker(this.props.markerLocation.latitude, this.props.markerLocation.longitude, this.props.markerLocation.radius, this.props.topic.id);
            }
            this.map.on('click', this.setLocation);
        }
    }

    scrollToBottom = () => {
        this.mapElement.scrollIntoView();
    }

    setLocation = (e) => {
        let coord = e.latlng;
        let lat = coord.lat;
        let lng = coord.lng;

        this.move = false;
        this.props.setMarkerLocation(lat, lng, this.props.markerLocation.radius);
    }

    markerOnClick = (e) => {
        if (this.props.showValues){
            //alert("hi. you clicked the marker at " + e.latlng);
        }
    }

    addMarker = (latitude, longitude, radius="", topicId = "", msg = "") => {
        try {
            latitude = latitude.replace(',', '.')
            longitude = longitude.replace(',', '.')
        } catch(e){
            
        }
        if(this.leafletIconSelection[topicId] === undefined)
            this.leafletIconSelection[topicId] = new LeafletIconClass({html: getHtmlPin("#00f", "")});

        if(radius != "" && radius != 0){
            this.circle = L.circle([latitude,longitude], parseInt(radius)).addTo(this.map);
        }
        
        this.marker = L.marker([latitude, longitude], {icon: this.leafletIconSelection[topicId]}).on('click', this.markerOnClick).addTo(this.map);

        if(this.move)
            this.map.panTo(new L.latLng(latitude, longitude));
        else
            this.move = true;

        if(msg !== "")
            this.marker.bindPopup(msg);
        
    }

    checkStyles = async (topics) => {
        let topic = "";
        let color = "";
        for(let i=0; i<topics.length; i++){
            topic = topics[i]
            if(this.leafletIconSelection[topic.id] === undefined){
                if(topic.mapMarkerStyle === undefined)
                    this.leafletIconSelection[topic.id] = new LeafletIconClass({html: getHtmlPin("#0Af", "")});
                else{
                    color = topic.mapMarkerStyle.color
                    if(topic.mapMarkerStyle.color == "")
                        color = "#0af"
                    this.leafletIconSelection[topic.id] = new LeafletIconClass({html: getHtmlPin(color, topic.mapMarkerStyle.faClass)});
                }
                this.layers[topic.id] = {topic, markers: []};
            }
        }
    }

    render() {
        return <div id="map" style={this.state.mapStyle} ref={(el) => { this.mapElement = el; }} location={this.props.markerLocation}></div>
    }
}

const mapStateToProps = state => ({
    markerLocation: state.markerLocation,
    mapProperties: state.mapProperties
});

export default connect(mapStateToProps, { setMarkerLocation, refreshMap })(Map);