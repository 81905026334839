import axios from 'axios';
import CryptoJS from "crypto-js";
import {Config} from '../config/config';

//let Config = (process.env.NODE_ENV === 'production') ? require('../config/config.prod.json') : require('../config/config.js');

//users

export function isLoggedIn() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users_user, {withCredentials: true})
            .then(res => {
                if(res.status === 200)
                        resolve(true);
                resolve(false);
            })
            .catch(err => {
                reject(false);
                console.log(err);
            });
    });
}

export function GetLogUser() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users_user, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
}

export function GetLogUserCompany() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users + "/user_company", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
}

export function ChangePassword(userId, oldPassword, newPassword) {
    let hash = CryptoJS.MD5(userId.toString()).toString(CryptoJS.enc.Base64);
    oldPassword = CryptoJS.AES.encrypt(oldPassword, hash).toString();
    newPassword = CryptoJS.AES.encrypt(newPassword, hash).toString();
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users_change_password, {oldPassword, newPassword}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => {
                console.log(err.response.data)
                if(err.response)
                    reject(err.response.data)
                else
                    reject(err)
            });
    });
}

export function ForgotPassword(emailUsername) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users + "/forgot_password", {emailUsername}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err));
    })
}

export function ResetPassword(token, password) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users + "/reset_password", {token, password}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err));
    })
}

export function CheckPassword(password) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users + "/check_password", {password}, {withCredentials: true})
            .then(res => {
                if(res.status === 200)
                    resolve(true);
                else
                    resolve(false);
            })
            .catch(err => {
                reject(err)
            });
    });
}


export function ChangeDetails(firstName, lastName, email, phone) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users_change_details, {firstName, lastName, email, phone}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
}

export function SignUp(newUser, company = undefined) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users_sign_up, {newUser, company}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

export function Login(username, password) {
    //let hash = CryptoJS.MD5(username).toString(CryptoJS.enc.Base64);
    //let hash_password = CryptoJS.AES.encrypt(password, hash).toString();
    //password = hash_password;

    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users_login, {username, password}, {withCredentials: true})
            .then(res => {
                if(res.status === 200){
                    console.log("Successful Login!");
                    resolve(res.data);
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}

export function LoginAsUser(userId) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users + "/login_as_user", {userId}, {withCredentials: true})
            .then(res => {
                if(res.status === 200){
                    console.log("Successful Login!");
                    resolve(res.data);
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}

export function Logout() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users_logout, {withCredentials: true})
            .then(res => {
                if(res.status === 200){
                    window.location = '/';
                    resolve(true);
                }      
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function GetUsers() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users, {withCredentials: true})
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function GetAdmins() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users + "/admins", {withCredentials: true})
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function AddAdmin(newAdmin) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users + "/add_admin", {newAdmin}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err));
    });
}

export function GetAdminUsersCompnay() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users + "/admin_users_company", {withCredentials: true})
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function GetUsersCompany() {
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_users + "/users_company", {withCredentials: true})
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function Pending(userId, accepted, mqtt, companyKey) {
    let request = { userId, accepted, mqtt, companyKey };
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_users_pending, request, {withCredentials: true})
            .then(res => {
                if(res.status === 200){
                    console.log(res);
                    resolve(res);
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}

export function DeleteUser(userId) {
    return new Promise((resolve, reject) => {
        axios.delete(Config.server.url_users + "/delete/" + userId,{withCredentials: true})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    })
}

//topics

export function GetTopics(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topics, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicById(topicId){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topics + "/" + topicId, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function AddTopic(newTopic){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topics_add, newTopic, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}

export function EditTopic(topicId, newData){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topics + "/edit", {topicId, newData}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => console.log(err))
    })
}

export function SetTopicStatus(topicId, status){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topics + "/set_status", {topicId, status}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => console.log(err))
    })
}

export function getTopicMapMarkerStyle(topicId){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topics + "/style/" + topicId, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => resolve(err))
    })
}

export function DeleteTopic(topicId, selectedTopicId){
    return new Promise((resolve, reject) => {
        axios.delete(Config.server.url_topics + "/" + topicId + "/" + selectedTopicId, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => resolve(err))
    })
}

export function PendingTopic(topicId, accepted){
    return new Promise((resolve, reject) => {
        axios.put(Config.server.url_topics + "/pending", {topicId, accepted}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => resolve(err))
    })
}

//companies

export function GetCompanies(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_companies, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function SignUpCompany(newCompany){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_companies_add, newCompany, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetCompanyById(id){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_companies_get + "/" + id, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetCompanyByKey(key){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_companies + "/get_by_key/" + key, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function ChangeMqttData(companyId, mqtt){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_companies + "/change_mqtt", {companyId, mqtt}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function ChangeKey(companyId, key){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_companies + "/change_key", {companyId, key}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function ChangeMqttId(mqttId){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_companies + "/change_mqtt_id", {mqttId}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}


export function ChangeCompanyDetails(name, address, email, phone) {
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_companies + "/change_details", {name, address, email, phone}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
    });
}

//topicCompanies

export function GetTopicsCompanies(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function AddTopicCompany(newTopicCompany){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topic_company_add, newTopicCompany, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

export function RemoveTopicCompany(id){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topic_company_remove, {id}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesPublish(id){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company + "/publish", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesPublishDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company + "/get_topic_pub", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesSubscribeDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company + "/get_topic_sub", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesAllDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company + "/get_topic_all", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

//topicCompaniesUser

export function GetTopicsCompaniesUser(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company_user, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function AddTopicCompanyUser(newTopicCompanyUser){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topic_company_user + "/add", newTopicCompanyUser, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

export function RemoveTopicCompanyUser(id){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_topic_company_user + "/remove", {id}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesUserPublishDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company_user + "/company_pub", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesUserSubscribeDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company_user + "/company_sub", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetTopicsCompaniesUserAllDetails(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_topic_company_user + "/company_all", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}
//tags - groups

export function GetDeviceGroupsTags(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_devices + "/tags", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

//devices

export function AddDevice(newDevice){
    try {
        newDevice.latitude = newDevice.latitude.replace(',', '.');
        newDevice.longitude = newDevice.longitude.replace(',', '.');
    }catch(e){

    }
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_devices_add, newDevice, {withCredentials: true})
            .then(res => {
                if(res.status === 200)
                    resolve(true);
            })
            .catch(err => reject(err))
    })
}

export function EditDevice(id, editedDevice){
    try{
        editedDevice.latitude = editedDevice.latitude.replace(',', '.');
        editedDevice.longitude = editedDevice.longitude.replace(',', '.');
    }catch(e){

    }
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_devices + "/edit", {id, editedDevice}, {withCredentials: true})
            .then(res => {
                if(res.status === 200)
                    resolve(true);
            })
            .catch(err => reject(err))
    })
}

export function GetDevicesTopicCompany(topicID){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_devices_get_topic_company + "/" + topicID, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function GetDevicesCompany(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_devices + "/get_company", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => console.log(err))
    })
}

export function EditMultipleDevices(changes){
    return new Promise((resolve, reject) => {
        axios.put(Config.server.url_devices + "/multiple", {changes}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

//mail

export function SendPendingMail(){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_mail + "/send", {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => console.log(err))
    })
}

//logs

export function GetLogs(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_logs, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

//msgs

export function GetMsgs(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_msgs, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

export function GetMsgsDevice(path){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_msgs + "/device", {topic: path}, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

//pending_permission

export function GetPendingPermission(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_pending_permission, {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

export function DeletePendingPermission(id){
    return new Promise((resolve, reject) => {
        axios.delete(Config.server.url_pending_permission + "/" + id, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}

export function AcceptPendingPermission(id){
    return new Promise((resolve, reject) => {
        axios.put(Config.server.url_pending_permission + "/accept", {id}, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}

export function GetCompanyPendingPermission(){
    return new Promise((resolve, reject) => {
        axios.get(Config.server.url_pending_permission + "/company", {withCredentials: true})
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}

export function AddPendingPermission(newPendingPermission){
    return new Promise((resolve, reject) => {
        axios.post(Config.server.url_pending_permission, newPendingPermission, {withCredentials: true})
            .then(res => resolve(res))
            .catch(err => reject(err))
    })
}