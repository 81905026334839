import React from 'react';
import * as Services from '../../utility/services';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';

class DeleteTopicModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            password: "",
            selectedTopic: ""
        }
    }

    onSelectTopic = (value) => {
        
    }

    onClickDelete = () => {
        if(this.state.selectedTopic !== ""){
            Services.CheckPassword(this.state.password).then(res => {
                if(res){
                    let selectedTopic = this.state.selectedTopic !== "delete" ? this.props.topics[this.state.selectedTopic]._id.toString() : "delete";
                    Services.DeleteTopic(this.props.topic._id.toString(), selectedTopic).then(res => {
                        alert("Successful")
                        $('#modelDeleteTopic').modal('hide')
                        window.location.reload();
                    }).catch(err => {
                        alert("Something went wrong.")
                        console.log(err)
                    })
                }
                else
                    alert("Wrong password")
            }).catch(err => {
                console.log(err)
            })
        }
        else
            alert("Please select what should happen to devices.")
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label htmlFor="selectTopic">Devices from {this.props.topic ? this.props.topic.topic : "topic"} should be moved to:</label>
                                <select className="form-control" id="selectTopic" value={this.state.selectedTopic} onChange={(e) => this.setState({selectedTopic: e.target.value})}>
                                    <option key={-1} value={""}></option>
                                    {this.props.topics.map((topic, index) => {
                                        return <option key={index} value={index}>{topic.topic}</option>
                                    })}
                                    <option key={"delete"} value={"delete"}>Nowhere - delete devices</option>
                                </select>
                            </div>
                            <br/><b/>
                            <div>
                                <label htmlFor="inputPassword">Enter your password:</label>
                                <input id="inputPassword" type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="passwordShow" onKeyDown={this.onInputKeyDown} onChange={(e) => this.setState({password: e.target.value})}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" onClick={this.onClickDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Modal extends React.Component {
    constructor(props){
        super(props);
        this.permission = "publish and subscribe"
    }

    objectDetails = (object) => {
        let array = [];

        for(let key in object) {
            if(Object.prototype.hasOwnProperty.call(object, key)){
                let value = object[key];
                if(typeof value !== "object")
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value + " "}</div>)
                else
                    array.push(<div key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{this.objectDetails(value)}</div>)
            }
        }
        return array;
    }

    onAccept = () => {
        this.onService(true)
    }

    onDelete = () => {
        this.onService(false)
    }

    addTopicCompany = (company_id, topic_id, type) => {
        let newTopicCompany = {
            company: company_id,
            topic: topic_id,
            type: type
        }
        Services.AddTopicCompany(newTopicCompany).catch(err => {
            console.log(err);
        })
    }

    onService = (accepted) => {
        Services.PendingTopic(this.props.topic._id, accepted).then(res => {
            let company_id = this.props.topic.pending._id
            let topic_id = this.props.topic._id
            if(this.permission == "publish and subscribe"){
                this.addTopicCompany(company_id, topic_id, "publish")
                this.addTopicCompany(company_id, topic_id, "subscribe")
            }
            else if(this.permission != "no permission")
                this.addTopicCompany(company_id, topic_id, this.permission)
            
            $('#modalDetails').modal('hide')
            this.props.refresh()
            //window.location.reload();
        }).catch(err => {
            console.log(err)
            alert("Something went wrong")
        })
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="permisson">Choose permissions for company {this.props.topic && this.props.topic.pending && this.props.topic.pending.name}: </label>
                                <select className="form-control" id="permisson" style={{width: 'auto'}} onChange={(e) => this.permission = e.target.value}>
                                    <option key={"publish and subscribe"} value={"publish and subscribe"}>publish and subscribe</option>
                                    <option key={"publish"} value={"publish"}>publish</option>
                                    <option key={"subscribe"} value={"subscribe"}>subscribe</option>
                                    <option key={"no permission"} value={"no permission"}>no permission</option>
                                </select>
                            </div> 
                            {this.objectDetails(this.props.topic)}
                        </div>
                        <div className="modal-footer">
                            {this.props.topic && this.props.topic.pending != undefined 
                            ?
                                <span>
                                    <button type="button" className="btn btn-success mr-1" onClick={this.onAccept}>Accept</button>
                                    <button type="button" className="btn btn-danger mr-1" onClick={this.onDelete}>Delete</button>
                                </span>
                                
                            :
                                null
                            }
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default class Topics extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            topicModal: null,
            topics: [],
            loading: false
        }

        this.columns = [{
            dataField: 'topic', 
            text: 'Topic',
            filter: textFilter()
          },{
            dataField: 'description', 
            text: 'Description', 
            filter: textFilter()
          },{
            dataField: 'properties', 
            text: 'Properties', 
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        {value.map(x => <div key={x.text + "_" + x.type + "_" + index}>{x.text} ({x.type}{x.timestamp ? " + ts" : ""})</div>)}
                    </div>
                )
            }
          },{
            dataField: 'status',
            text: 'Status',
            formatter: (value, row, index, field) => {
                if(!row.pending)
                    return (
                        <div>
                            <select className="form-control" id="statusOption" style={{width: 'auto'}} value={row.status} onChange={(e) => this.onChangeStatus(row, e.target.value, index)}>
                                <option key={"active"} value={"active"}>Active</option>
                                <option key={"disabled"} value={"disabled"}>Disabled</option>
                            </select>
                        </div>
                    )
            }
          },{
            dataField: 'actions',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            formatter: (value, row, index, field) => {
                if(row.pending == undefined)
                    return (
                        <div>
                            <button type="button" className="btn btn-dark mr-1" data-toggle="modal" data-target="#modalDetails" onClick={() => {this.setState({topicModal: row})}}>Details</button>
                            <button type="button" className="btn btn-primary mr-1" onClick={() => this.onClickEdit(row)}>Edit</button>
                            <button type="button" className="btn btn-danger mr-1" data-toggle="modal" data-target="#modelDeleteTopic" onClick={() => {this.setState({topicModal: row})}}>Delete</button>
                        </div>
                    )
                else
                    return (
                        <div>
                            <button type="button" className="btn btn-warning mr-1" data-toggle="modal" data-target="#modalDetails" onClick={() => {this.setState({topicModal: row})}}>Pending</button>
                        </div>
                    ) 
            }
          }
        ];
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetTopics().then(res => {
            this.setState({topics: res, loading: false});
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        })
    }

    onChangeStatus = (topic, status, index) => {
        let response = window.confirm("Please confirm: " + topic.topic + " set to " + status.toString().toUpperCase());
        if(response){
            Services.SetTopicStatus(topic._id, status).then(res => {
                Services.GetTopics().then(res => this.setState({topics: res}));
            });
        }
    }

    onClickEdit = (topic) => {
        this.props.history.push({
            pathname: '/edit_topic',
            state: {topicId: topic._id}
        });
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Topics</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.topics} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                    </div>
                </div>
                <Modal id={"modalDetails"} title={"Details"} topic={this.state.topicModal} refresh={this.refresh}/>
                <DeleteTopicModal id={"modelDeleteTopic"} title={"Delete topic"} topic={this.state.topicModal} topics={this.state.topics} refresh={this.refresh}/>
            </div>
        );
    }
}