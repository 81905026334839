import React, { Fragment } from 'react';
import * as Services from '../utility/services';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';

var groupBy = function (arr, criteria) {
	return arr.reduce(function (obj, item) {
		// Check if the criteria is a function to run on the item or a property of it
		var key = typeof criteria === 'function' ? criteria(item) : item[criteria];
		// If the key doesn't exist yet, create it
		if (!obj.hasOwnProperty(key)) {
			obj[key] = [];
		}
		// Push the value to the object
		obj[key].push(item);
		// Return the object to the next item in the loop
		return obj;
	}, {});
};

function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export default class TopicCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            companies: [],
            topicCompanies: [],
            selectRights: "publish",
            selectProperties: [],
            selectDevices: "all",
            selectCompany: -1,
            selectTopic: -1,
            loading: false,
            grouped: [],
            searchCompanyString: "",
            searchTopicString: "",
            companyId: "",
            tagsGroups: []
        }

        this.typingTimeout = 0;
        this.typing = false;

        this.selectOptions = {
            subscribe: 'subscribe',
            publish: 'publish'
        }

        this.columns = [{
            dataField: 'topic.topic', 
            text: 'Topic',
            /*formatter: (value, row, index, field) => {
                return value + " (" + row.topic._id + ")";
            },*/
            filter: textFilter()
          },{
            dataField: 'company.name',
            text: 'Company',
            filter: textFilter(),
            /*formatter: (value, row, index, field) => {
                return value + " (" + row.company._id + ")";
            },*/
          },{
            dataField: 'type', 
            text: 'Rights', 
            filter: selectFilter({options: this.selectOptions})
          },{
            dataField: 'actions',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        <button type="button" className="btn btn-danger mr-1" onClick={() => this.onRemove(row._id)}>Delete</button>
                    </div>
                )
            }
          }
        ];
    }

    componentDidMount() {
        this.loadGroups()

        Services.GetLogUserCompany().then(res => {
            this.setState({companyId: res.company._id})
        })

        this.setState({loading: true});
        Services.GetTopicsCompaniesUser().then(res => {
            let grouped = groupBy(res, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
            this.setState({topicCompanies: res, loading: false, grouped})
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        });
        Services.GetTopicsCompaniesPublish().then(res => {
            this.setState({topics: res.map(x => x.topic)})
        });
        Services.GetCompanies().then(res => {
            let array = res.filter(x => x.accepted === 1);
            this.setState({companies: array})
        });
    }

    loadGroups = () => {
        Services.GetDeviceGroupsTags().then(res => {
            let tagsGroups = groupBy(res, element => element.topic);
            for(let key in tagsGroups){
                let groups = []
                let single = []
                tagsGroups[key].forEach(x => {
                    if (x.tags.length == 0)
                        single.push({device_id: x.device_id, _id: x._id, title: x.title})
                    else
                        groups.push(x.tags)
                })
                groups = flatten(groups)

                tagsGroups[key] = {groups, single};
            }
            this.setState({tagsGroups})
        }).catch(err => console.log(err))
    }
    
    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});

        if(e.target.id == "selectTopic")
            this.setState({selectProperties: this.state.topics[e.target.value].properties});
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetTopicsCompaniesUser().then(res => {
            let grouped = groupBy(res, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
            this.setState({topicCompanies: res, loading: false, grouped})
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        });
    }

    addTopicCompany = (rights) => {
        if(this.state.selectCompany !== -1 && this.state.selectTopic !== -1){
            let company = this.state.companies[this.state.selectCompany];
            let topic = this.state.topics[this.state.selectTopic];
            let newTopicCompany = null
            if(typeof(this.state.selectDevices) == 'string')
                newTopicCompany = {
                    company: company._id,
                    topic: topic._id,
                    type: rights,
                    properties: this.state.selectProperties,
                    group: this.state.selectDevices.toString()
                }
            else
                newTopicCompany = {
                    company: company._id,
                    topic: topic._id,
                    type: rights,
                    properties: this.state.selectProperties,
                    single: this.state.selectDevices._id.toString()
                }
            Services.AddTopicCompanyUser(newTopicCompany).then(res => {
                this.refresh();
            })
            .catch(err => {
                console.log(err);
                alert("Link already exists");
            })
        }
        else
            alert("Please select.");
    }

    add = () => {
        if(this.state.selectRights == 'both'){
            this.addTopicCompany('publish')
            this.addTopicCompany('subscribe')
        }
        else
            this.addTopicCompany(this.state.selectRights)
    }

    onRemove = (id) => {
        Services.RemoveTopicCompanyUser(id).then(res => {
            this.refresh();
        });
    }

    deleteConnection = (doc) => {
        let r = window.confirm("Are you sure you want to delete: " + doc.topic.topic + " - " + doc.company.name + " type " + doc.type);
        if(r){
            Services.RemoveTopicCompanyUser(doc._id).then(res => {
                let topicCompanies = [...this.state.topicCompanies];
                let index = topicCompanies.indexOf(doc);
                topicCompanies.splice(index, 1);
                let grouped = groupBy(topicCompanies, element => element.company._id);
                for(let key in grouped){
                    let company = groupBy(grouped[key], x => x.topic._id)
                    grouped[key] = company;
                }
                this.setState({topicCompanies: topicCompanies, grouped})
            });
        }
    }

    createConnection = (companyId, topicId, type) => {
        let newTopicCompany = {
            company: companyId,
            topic: topicId,
            type: type
        }
        Services.AddTopicCompanyUser(newTopicCompany).then(res => {
            this.refresh();
        }).catch(err => {
            console.log(err);
        })
    }

    remove = (link) => {
        Services.RemoveTopicCompanyUser(link._id).then(res => {
            console.log(res)
            this.refresh()
        }).catch(e => console.log(e))
    }

    mapGrouped = () => {
        let grouped = this.state.grouped;

        if(this.state.searchCompanyString !== "" || this.state.searchTopicString !== ""){
            let searchCompanyString = this.state.searchCompanyString.toLowerCase();
            let searchTopicString = this.state.searchTopicString.toLowerCase();

            let filter = null;
            if(this.state.searchCompanyString !== "" && this.state.searchTopicString !== "")
                filter = this.state.topicCompanies.filter(x => x.topic.topic.toLowerCase().includes(searchTopicString) && x.company.name.toLowerCase().includes(searchCompanyString));
            else if(this.state.searchCompanyString !== "")
                filter = this.state.topicCompanies.filter(x => x.company.name.toLowerCase().includes(searchCompanyString));
            else if(this.state.searchTopicString !== "")
                filter = this.state.topicCompanies.filter(x => x.topic.topic.toLowerCase().includes(searchTopicString));

            grouped = groupBy(filter, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
        }

        let group = null;
        let result = [];
        let companyName = "";
        let topicName = "";
        let tableBody = [];
        let subGroup = null;
        let publish = false;
        let subscribe = false;
        let current = null;

        for(let key in grouped){
            group = grouped[key];
            tableBody = [];
            for(let topicKey in group){
                for(let current_index in group[topicKey]){
                    publish = null;
                    subscribe = null;
                    current = group[topicKey][current_index]

                    companyName = current.company.name;
                    topicName = current.topic.topic;

                    //subGroup.forEach(element => {
                        if(current.type === "publish")
                            publish = <Checkbox checked={true} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.deleteConnection(current)}/>;
                        else 
                            subscribe = <Checkbox checked={true} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.deleteConnection(current)}/>;
                    //});
                    tableBody.push(
                        <TableRow hover role="checkbox" tabIndex={-1} key={key + "_" + topicKey + "_" + current_index}>
                            <TableCell align={'center'}>
                                {topicName}
                            </TableCell>
                            <TableCell align={'center'}>
                                {current.single ? current.single : current.group}
                            </TableCell>
                            <TableCell align={'center'}>
                                {current.properties.map(x => x.text + "_" + x.type).join(', ')}
                            </TableCell>
                            <TableCell align={'center'}>
                                {current.type}
                            </TableCell>
                            <TableCell align={'center'}>
                                <button className="btn btn-sm btn-danger" onClick={() => this.remove(current)}>Remove</button>
                            </TableCell>
                            {/*<TableCell align={'center'}>
                                {publish ? publish : <Checkbox checked={false} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.createConnection(key, topicKey, "publish")}/>}
                            </TableCell>
                            <TableCell align={'center'}>
                                {subscribe ? subscribe : <Checkbox checked={false} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.createConnection(key, topicKey, "subscribe")}/>}
                            </TableCell>*/}
                        </TableRow>
                    );
                }
            }
            result.push(
                <Fragment key={key}>
                    <TableHead style={{backgroundColor: "#ccc"}} key={"tableHead" + key}>
                        <TableRow>
                            <TableCell align={'left'} style={{backgroundColor: "#ddd", fontWeight: 'bold', width: "30%"}}>
                                {companyName}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Devices"}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Properties"}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Type"}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Actions"}
                            </TableCell>
                            {/*<TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Publish"}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Subscribe"}
                            </TableCell>*/}
                            {/*
                            <TableCell align={'right'} style={{backgroundColor: "#ddd", width: '1px'}}>
                                <button className="btn btn-sm btn-link"><AddIcon/></button>
                            </TableCell>
                            */} 
                        </TableRow>
                    </TableHead>
                    <TableBody key={"tableBody" + key}>{tableBody}</TableBody>
                </Fragment>
            );
        }
        return result;
    }

    searchChange = (e, variable) => {
        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        let value = e.target.value;
        this.typingTimeout = setTimeout(() => {
            this.setState({[variable]: value});
        }, 400);
    }

    generateOptionsTagGroups = () => {
        let options = []
        if(this.state.selectTopic != -1){
            let topic_id = this.state.topics[this.state.selectTopic]._id
            if(this.state.tagsGroups[topic_id] != undefined){
                this.state.tagsGroups[topic_id].groups.forEach((x, index) => {
                    options.push(<option key={x} value={x}>{x}</option>)
                })
                this.state.tagsGroups[topic_id].single.forEach((x, index) => {
                    options.push(<option key={x._id} value={x._id}>{x.device_id + " - " + x.title}</option>)
                })
            }
        }
        return options
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Topic - Company</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="selectTopic">Select Topic</label>
                                    <select className="form-control" id="selectTopic" onChange={this.onChange}>
                                        <option value={-1}>Select topic</option>
                                        {this.state.topics.map((topic, index) => (
                                            <option key={topic._id} value={index}>{topic.topic}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="selectCompany">Select Company</label>
                                    <select className="form-control" id="selectCompany" onChange={this.onChange}>
                                        <option value={-1}>Select Company</option>
                                        {this.state.companies.map((company, index) => (
                                            this.state.companyId != company._id ?
                                            <option key={company._id} value={index}>{company.name}</option>
                                            : null
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="selectDevices">Devices</label>
                                    <select className="form-control" id="selectDevices" onChange={this.onChange}>
                                        <option value="all">All</option>
                                        {this.generateOptionsTagGroups()}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                {/*<div className="form-group">
                                    <label htmlFor="selectProperties">Properties</label>
                                    <select className="form-control" id="selectProperties" onChange={this.onChange}>
                                        <option value="all">All</option>
                                        {this.state.selectTopic != -1 ? 
                                            this.state.topics[this.state.selectTopic].properties.map(property => {
                                                return <option value={property} key={property.text + property.type + this.state.selectTopic}>{property.text + "_" + property.type}</option>
                                            })
                                            : null
                                        }
                                    </select>
                                    </div>*/}
                                <div className="form-group">
                                    <label htmlFor="selectProperties">Properties</label>
                                    <FormControl className="form-control">
                                        <Select
                                        id="selectProperties"
                                        multiple
                                        style={{height: 38, border: "1px solid #ced4da", borderRadius: "0.25rem", padding: "0.375rem 0.75rem"}}
                                        value={this.state.selectProperties}
                                        onChange={e => this.setState({selectProperties: e.target.value})}
                                        input={<Input />}
                                        renderValue={(selected) => {return selected.map(x => x.text + "_" + x.type).join(', ')}}
                                        >
                                        {this.state.selectTopic != -1 ?  this.state.topics[this.state.selectTopic].properties.map((property) => {
                                            return <MenuItem key={property.text + property.type} value={property}>
                                                <Checkbox checked={this.state.selectProperties.indexOf(property) > -1} />
                                                <ListItemText primary={property.text + "_" + property.type} />
                                            </MenuItem>
                                        }) : null}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="selectRights">Rights</label>
                                    <select className="form-control" id="selectRights" onChange={this.onChange}>
                                        <option value="publish">publish</option>
                                        <option value="subscribe">subscribe</option>
                                        <option value="both">publish and subscribe</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary" onClick={this.add}>Add</button>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>
                        <div className="row" style={{marginBottom: 20}}>
                            <div className="col-sm">
                                <input className="form-control" type="text" placeholder="Search Company" onChange={e => this.searchChange(e, 'searchCompanyString')}/>
                            </div>
                            <div className="col-sm">
                                <input className="form-control" type="text" placeholder="Search Topic" onChange={e => this.searchChange(e, 'searchTopicString')}/>
                            </div>
                        </div>
                        
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <span>*Cross-rights checking and overlap have not yet been implemented</span>
                        <div className="row">
                            <div className="col-sm">
                                <Paper style={{width: "100%", border: "1px solid #ddd"}}>
                                    <TableContainer style={{maxHeight: 440}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            {this.mapGrouped(this.state.searchString)}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                        {/*
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.topicCompanies} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                        */}
                    </div>
                </div>
            </div>
        );
    }
}