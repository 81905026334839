import React from 'react';
import * as Services from '../../utility/services';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import ModalDPR from '../../components/ModalDPR';
import LinearProgress from '@material-ui/core/LinearProgress';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
const uuidv1 = require('uuid/v1');

export default class Companies extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            userModal: null,
            mqttId: null,
            toggleActive: 0,
            togglePending: 0,
            toggleRejected: 0,
            //statusFilter: undefined,
            loading: false
        }

        this.statusFilter = undefined;

        this.selectOptions = {
            '-1': 'rejected',
            '0': 'pending',
            '1': 'accepted' 
        };

        /*this.selectOptions = {
            'rejected': 'rejected',
            'pending': 'pending',
            'accepted': 'accepted' 
        };*/

        this.columns = [{
            dataField: 'company.name', 
            text: 'Company name',
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'firstName', 
            text: 'First name', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'lastName', 
            text: 'Last name', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          }, {
            dataField: 'username', 
            text: 'Username', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'email', 
            text: 'E-mail', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'phone', 
            text: 'Phone', 
            filter: textFilter()
          },{
            dataField: 'accepted',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            filter: multiSelectFilter({
                options: this.selectOptions,
                style: {
                    visibility: "collapse",
                    height: 0
                },
                withoutEmptyOption: true,
                getFilter: (filter) => {
                    // qualityFilter was assigned once the component has been mounted
                    this.statusFilter = filter;
                    //this.setState({statusFilter: filter});
                }
            }),
            formatter: (value, row, index, field) => {
                if(row.accepted === 1 || row.accepted === "accepted")
                    return (
                        <div>
                            <button type="button" className="btn btn-dark mr-1" data-toggle="modal" data-target="#modalDetails" onClick={() => {this.setState({userModal: row})}}>Details</button>
                            <button type="button" className="btn btn-primary mr-1" onClick={() => this.onCompanyEdit(row)}>Edit</button>
                            <button type="button" className="btn btn-link mr-1" onClick={() => this.onLogin(row)}>
                                <VpnKeyIcon />
                            </button>
                        </div>
                    )
                else if(row.accepted === 0 || row.accepted === "pending")
                    return(
                        <div>
                            <button type="button" className="btn btn-warning mr-1" data-toggle="modal" data-target="#modalPending" onClick={() => {this.setState({userModal: row})}}>Pending</button> 
                        </div>
                    )
                else if(row.accepted === -1 || row.accepted === "rejected")
                    return(
                        <div>
                            <button type="button" className="btn btn-danger mr-1" data-toggle="modal" data-target="#modalRejected" onClick={() => {this.setState({userModal: row})}}>Rejected</button> 
                        </div>
                    )
            }
          },{
            dataField: 'createdAt',
            text: 'CreatedAt',
            type: "date",
            hidden: true,
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            sort: true
          }
        ];
    }

    componentDidMount() {
        this.refresh();
        this.table.sortContext.handleSort(this.columns[7]);
    }

    onLogin = (user) => {
        if(window.confirm('Do you want to sign in as ' +  user.firstName + " " + user.lastName + ' "' + user.username + '"?')){
            Services.LoginAsUser(user._id)
            .then(res => {
                console.log(res)
                window.location = "/profile";
            })
            .catch(err => {
                console.log(err.response)
            })
        }
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetAdminUsersCompnay().then(users => {
            //this.setState({users: users});
            //console.log(users);
            /*Services.GetCompanies().then(companies => {
                //console.log(companies);
                let array = users.map(user => {
                    if(user.accepted === -1)
                        user.accepted = "rejected"
                    else if(user.accepted === 0)
                        user.accepted = "pending"
                    else if(user.accepted === 1)
                        user.accepted = "accepted"
                    let company = companies.find(c => c._id === user.companyID);
                    let newUser = user;
                    newUser.company = company;
                    return newUser;
                });
                //console.log(array)
                this.setState({users: array, loading: false});
            })*/
            this.setState({users: users, loading: false});
        })
        //this.table.sortContext.handleSort(this.columns[7]);
        //Services.GetCompanies().then(res => console.log(res))
    }
    
    onCompanyEdit = (user) => {
        this.props.history.push({
            pathname: '/edit_company',
            state: {user}
        });
    }

    getStatusFilterArray = (toggleActive, togglePending, toggleRejected) => {
        let array = [];
        if(toggleActive)
            array.push("1");
        if(togglePending)
            array.push("0");
        if(toggleRejected)
            array.push("-1");
        return array;
    }

    indication = (loading) => {
        if(loading)
            return (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
        else
            return "Empty"
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Companies</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="btn-group btn-group-toggle mr-1">
                            <label className={this.state.toggleActive ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox" autoComplete="off" onChange={(e) => {
                                    this.setState({toggleActive: e.target.checked}); 
                                    this.statusFilter(this.getStatusFilterArray(e.target.checked, this.state.togglePending, this.state.toggleRejected))}}/> Active
                            </label>
                            <label className={this.state.togglePending ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox"  autoComplete="off" onChange={(e) => {
                                    this.setState({togglePending: e.target.checked});
                                    this.statusFilter(this.getStatusFilterArray(this.state.toggleActive, e.target.checked, this.state.toggleRejected))}}/> Pending
                            </label><label className={this.state.toggleRejected ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox" autoComplete="off" onChange={(e) => {
                                    this.setState({toggleRejected: e.target.checked});
                                    this.statusFilter(this.getStatusFilterArray(this.state.toggleActive, this.state.togglePending, e.target.checked))}}/> Rejected
                            </label>
                        </div>

                        <div className="btn-group btn-group-toggle">
                            <label className={this.state.toggleDate ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox" autoComplete="off" onChange={(e) => {
                                    this.table.sortContext.handleSort(this.columns[7]);}}/> DateSort
                            </label>
                        </div>

                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>

                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <BootstrapTable 
                            ref={ n => this.table = n }
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.users} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                        
                    </div>
                </div>
                {/* modal */}
                <ModalDPR id={"modalDetails"} title={"Details"} user={this.state.userModal} type={"details"} refresh={this.refresh}/>
                <ModalDPR id={"modalPending"} title={"Pending"} user={this.state.userModal} type={"pending"} refresh={this.refresh}/>
                <ModalDPR id={"modalRejected"} title={"Rejected"} user={this.state.userModal} type={"rejected"} refresh={this.refresh}/>
            </div>
        );
    }
}