import { 
    LOG_IN_FULFILLED,
    SET_MARKER_LOCATION,
    SET_MARKER_LATITUDE,
    SET_MARKER_LONGITUDE,
    SET_MARKER_RADIUS,
    REFRESH_MAP,
    GET_USER_FULFILLED,
    GET_USER_REJECTED,
    SET_USER
} from '../types';
import { combineReducers } from 'redux';
//import { localizeReducer  } from 'react-localize-redux';

export const userReducer = (state = {username: undefined, type: undefined, firstName: undefined, lastName: undefined}, action) => {
    switch(action.type){
        case LOG_IN_FULFILLED:
            return Object.assign({}, state, {
                username: (action.payload.username != null) ? action.payload.username : state.username,
                type: (action.payload.type != null) ? action.payload.type : state.type,
                firstName: (action.payload.firstName != null) ? action.payload.firstName : state.firstName,
                lastName: (action.payload.lastName != null) ? action.payload.lastName : state.lastName,
                accepted: (action.payload.accepted != null) ? action.payload.accepted : state.accepted
            });
        case GET_USER_FULFILLED:
            return Object.assign({}, state, {
                username: (action.payload.username != null) ? action.payload.username : state.username,
                type: (action.payload.type != null) ? action.payload.type : state.type,
                firstName: (action.payload.firstName != null) ? action.payload.firstName : state.firstName,
                lastName: (action.payload.lastName != null) ? action.payload.lastName : state.lastName,
                accepted: (action.payload.accepted != null) ? action.payload.accepted : state.accepted
            });
        case GET_USER_REJECTED:
            if(state.username === null)
                return state;
            else
                return Object.assign({}, state, {
                    username: null,
                    type: null,
                    firstName: null,
                    lastName: null,
                    accepted: null
                });
        case SET_USER:
            return Object.assign({}, state, {
                username: (action.payload.username != null) ? action.payload.username : state.username,
                type: (action.payload.type != null) ? action.payload.type : state.type,
                firstName: (action.payload.firstName != null) ? action.payload.firstName : state.firstName,
                lastName: (action.payload.lastName != null) ? action.payload.lastName : state.lastName,
                accepted: (action.payload.accepted != null) ? action.payload.accepted : state.accepted
            });
        default:
            return state;
    }
}

export const markerLocationReducer = (state = {latitude: '', longitude: '', radius: ""}, action) => {
    switch(action.type){
        case SET_MARKER_LOCATION:
            return action.payload;
        case SET_MARKER_LATITUDE:
            return {latitude: action.payload, longitude: state.longitude, radius: state.radius};
        case SET_MARKER_LONGITUDE:
            return {latitude: state.latitude, longitude: action.payload, radius: state.radius};
        case SET_MARKER_RADIUS:
            return {latitude: state.latitude, longitude: state.longitude, radius: action.payload};
        default:
            return state;
    }
}

export const mapPropertiesReducer = (state = {refresh: false}, action) => {
    switch (action.type) {
        case REFRESH_MAP:
            return {refresh: action.payload}
        default:
            return state;
    }
}

export const allReducers = combineReducers({
    user: userReducer,
    markerLocation: markerLocationReducer,
    mapProperties: mapPropertiesReducer,
    //localize: localizeReducer
});