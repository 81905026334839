import React from 'react';
import * as Services from '../utility/services';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import ModalDPR from '../components/ModalDPR'

import LinearProgress from '@material-ui/core/LinearProgress';

export default class Users extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            userModal: null,
            toggleActive: 0,
            togglePending: 0,
            toggleRejected: 0,
            //statusFilter: undefined,
            loading: false
        }

        this.statusFilter = undefined;

        this.selectOptions = {
            '-1': 'rejected',
            '0': 'pending',
            '1': 'accepted' 
        };

        this.columns = [{
            dataField: 'firstName', text: 'First name', filter: textFilter()
          },{
            dataField: 'lastName', 
            text: 'Last name', 
            filter: textFilter()
          }, {
            dataField: 'username', 
            text: 'Username', 
            filter: textFilter()
          },{
            dataField: 'email', 
            text: 'E-mail', 
            filter: textFilter()
          },{
            dataField: 'phone', 
            text: 'Phone', 
            filter: textFilter()
          },{
            dataField: 'accepted',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            filter: multiSelectFilter({
                options: this.selectOptions,
                style: {
                    visibility: "collapse",
                    height: 0
                },
                withoutEmptyOption: true,
                getFilter: (filter) => {
                    // qualityFilter was assigned once the component has been mounted
                    this.statusFilter = filter;
                    //this.setState({statusFilter: filter});
                }
            }),
            formatter: (value, row, index, field) => {
                if(row.accepted === 1)
                return (
                    <div>
                        <button type="button" className="btn btn-dark mr-1" data-toggle="modal" data-target="#modalDetails" onClick={() => {this.setState({userModal: row})}}>Details</button>
                        {/*<button type="button" className="btn btn-primary mr-1" onClick={() => this.onUserEdit(row)}>Edit</button>*/}
                        {/*<button type="button" className="btn btn-danger" onClick={() => {}}>Delete</button>*/}
                    </div>
                )
                else if(row.accepted === 0)
                    return(
                        <div>
                            <button type="button" className="btn btn-warning mr-1" data-toggle="modal" data-target="#modalPending" onClick={() => {this.setState({userModal: row})}}>Pending</button> 
                        </div>
                    )
                else if(row.accepted === -1)
                    return(
                        <div>
                            <button type="button" className="btn btn-danger mr-1" data-toggle="modal" data-target="#modalRejected" onClick={() => {this.setState({userModal: row})}}>Rejected</button> 
                        </div>
                    )
            }
          }
        ];
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetUsersCompany().then(users => {
            this.setState({users, loading: false});
        });
    }
    
    onUserEdit = (user) => {
        this.props.history.push({
            pathname: '/users',
            state: {user}
        });
    }

    getStatusFilterArray = (toggleActive, togglePending, toggleRejected) => {
        let array = [];
        if(toggleActive)
            array.push("1");
        if(togglePending)
            array.push("0");
        if(toggleRejected)
            array.push("-1");
        return array;
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Users</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="btn-group btn-group-toggle">
                            <label className={this.state.toggleActive ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox" autoComplete="off" onChange={(e) => {
                                    this.setState({toggleActive: e.target.checked}); 
                                    this.statusFilter(this.getStatusFilterArray(e.target.checked, this.state.togglePending, this.state.toggleRejected))}}/> Active
                            </label>
                            <label className={this.state.togglePending ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox"  autoComplete="off" onChange={(e) => {
                                    this.setState({togglePending: e.target.checked});
                                    this.statusFilter(this.getStatusFilterArray(this.state.toggleActive, e.target.checked, this.state.toggleRejected))}}/> Pending
                            </label><label className={this.state.toggleRejected ? "btn btn-dark active" : "btn btn-secondary"}>
                                <input type="checkbox" autoComplete="off" onChange={(e) => {
                                    this.setState({toggleRejected: e.target.checked});
                                    this.statusFilter(this.getStatusFilterArray(this.state.toggleActive, this.state.togglePending, e.target.checked))}}/> Rejected
                            </label>
                        </div>

                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>

                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        {/*this.getTable(this.state.users, ["First Name", "Last Name", "Username", ""], ["firstName", "lastName", "username", ""])*/}
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.users} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                        /> 
                    </div>
                </div>
                {/* modal */}
                <ModalDPR id={"modalDetails"} title={"Details"} user={this.state.userModal} type={"details"} refresh={this.refresh}/>
                <ModalDPR id={"modalPending"} title={"Pending"} user={this.state.userModal} type={"pending"} refresh={this.refresh}/>
                <ModalDPR id={"modalRejected"} title={"Rejected"} user={this.state.userModal} type={"rejected"} refresh={this.refresh}/>
            </div>
        );
    }
}