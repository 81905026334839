import React from 'react';

export default function TermsConditions() {
    return(
        <div>
            <div className="jumbotron">
                <div className="container">
                    <h1>Terms and Conditions</h1>
                </div>
            </div>
            <div className="container">
                <p>

                </p>
            </div>
        </div>
    );
}