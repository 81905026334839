import React from 'react';
import '../App.css';
import Map from "../components/Map";
import { connect } from 'react-redux';
import {setMarkerLocation,setMarkerLatitude,setMarkerLongitude} from '../redux/actions';
import "../../node_modules/bootstrap/dist/js/bootstrap";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import * as Services from '../utility/services';

class Profile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            detailsUser: null,
            detailsCompany: null,
            devices: [],
            //mqtt: null,
            //mqttShow: false,
            //password: ""
        }
    }

    componentDidMount() {
        Services.GetDevicesCompany().then(res => {
            this.setState({devices: res})
        });
        Services.GetLogUserCompany()
            .then(res => {
                let user = res.user;
                let company = res.company;
                //let mqtt = res.company.mqtt;
                //mqtt.password = CryptoJS.AES.decrypt(mqtt.password, CryptoJS.MD5(company._id).toString(CryptoJS.enc.Base64)).toString(CryptoJS.enc.Utf8);
                this.setState({detailsUser: this.getDetails(user, ["First name", "Last name", "Phone", "Email"],["firstName", "lastName", "phone", "email"])});
                this.setState({detailsCompany: this.getDetails(company, ["Name", "Address", "Phone", "Email", "Company KEY"],["name", "address", "phone", "email", "companyKey"])});
                //this.setState({mqtt: this.getDetails(mqtt, ["IP", "Port", "Username", "Password"],["ip", "port", "username", "password"])});
            })
            .catch(err => console.log(err));
    }

    getDetails = (object, header, props) => {
        let array = [];
        props.forEach((prop, index) => {
            let value = object[prop];
            array.push(
                <tr key={prop + object._id}>
                    <th scope="row">{header[index]}</th>
                    <td>{value}</td>
                </tr>
            );
        });
        return array;
    }

    onShowMQTT = () => {
        Services.CheckPassword(this.state.password).then(res => {
            this.setState({mqttShow: res});
        }).catch(err => alert("Wrong password"))
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Profile</h1>
                        </div>
                    </div>
                    <div className="container">
                        <h3>User details</h3>
                        <table className="table table-striped">
                            <tbody>
                                {this.state.detailsUser}
                            </tbody>
                        </table>
                        <hr/>
                        <h3>Company details</h3>
                        <table className="table table-striped">
                            <tbody>
                                {this.state.detailsCompany}
                            </tbody>
                        </table>
                        <hr/>
                        {/*<h3>MQTT</h3>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="passwordShow" onChange={(e) => this.setState({password: e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="button" id="passwordShow" onClick={this.onShowMQTT}>Show MQTT data</button>
                            </div>
                        </div>
                        <table className="table table-striped">
                            <tbody>
                                {this.state.mqttShow ? this.state.mqtt : null }
                            </tbody>
                        </table>*/}

                        <Map latitude={46.554649} longitude={15.645881} zoom={14} multiple={this.state.devices} showValues={true}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    markerLocation: state.markerLocation
});

export default connect(mapStateToProps, { setMarkerLocation, setMarkerLatitude, setMarkerLongitude })(Profile);