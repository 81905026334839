import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';


export default class AdminMQTT extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: ">ip1|port1|user1|pass1\nip1|port1|user2|pass2\nip1|port1|user3|pass3\nip1|port1|user4|pass4\nip1|port1|user5|pass5\nip1|port1|user6|pass6\nip1|port1|user7|pass7",
            loading: false
        }
    }

    sparseData = () => {
        let array = [];
        let parts = [];
        let line = { ip: "", port: "", username: "", password: "", used: false};
        this.state.data.split("\n").forEach(row => {
            line.used = false;
            parts = row.split("|").map(x => x.replace(/\s/g, ''));
            if(parts.length === 4){
                if(parts[0].length > 0){
                    if(parts[0][0] === '>'){
                        parts[0] = parts[0].substr(1);
                        line.used = true;
                    }
                }
                line.ip = parts[0];
                line.port = parts[1];
                line.username = parts[2];
                line.password = parts[3];
                array.push({...line});
            }
        });
        console.log(array);
    }

    saveChanges = () => {

    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">MQTT</h1>
                        </div>
                    </div>
                    <div className="container">
                        {/*<div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.loadFromFile}>Load from file</button>
                        </div>*/}
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-primary mr-3" onClick={this.sparseData}>Sparse data</button>
                            <button type="button" className="btn btn-primary" onClick={this.saveChanges}>Save changes</button>
                        </div>
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <div className="form-group">
                            {/*<label htmlFor="inputData">Data</label>*/}
                            <textarea className="form-control" type="text" id="inputData" value={this.state.data} onChange={(e) => this.setState({data: e.target.value})} rows="20"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}