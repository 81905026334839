import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {logIn} from '../redux/actions';
import {connect} from 'react-redux';

const UserTypes = require('../utility/userTypes.json');

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: "",
            password: "",
            alert: false,
            alertMsg: "",
            loading: false
        }
    }

    componentDidUpdate(prevProps) {        
        if (this.props.user !== prevProps.user) {
            (this.props.user.type === UserTypes.admin || this.props.user.type === UserTypes.super_admin) ? this.props.history.push('/companies') : this.props.history.push('/profile');
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onLogin = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        this.props.logIn(this.state.username, this.state.password)
            .then(res => res)
            .catch(err => {
                if(err.response){
                    if(err.response.status === 401){
                        this.setState({alert: true, alertMsg: "Username or password is incorrect.", loading: false});
                    } else {
                        console.log(err.response)
                        this.setState({alert: true, alertMsg: "Something went wrong.", loading: false});
                    }
                }
                else {
                    console.log(err.response)
                    this.setState({alert: true, alertMsg: "Something went wrong.", loading: false});
                }
                
            })
    }

    onSignUp = () => {
        this.props.history.push("/sign_up");
    }

    onForgotPassword = () => {
        this.props.history.push("/forgot_password");
    }

    render() {
        return (
            <div className="App">
                <div>
                    <div className="LoginLogo">
                        {window.REACT_APP_TITLE == "UMPlatforma" ?
                            <h2><b className='blue'>UM</b>Platforma</h2>
                        :
                            <h2><b>{window.REACT_APP_TITLE}</b></h2>
                        }
                    </div>
                    <div className="LoginForm">
                        {this.state.alert === true
                            ? 
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {this.state.alertMsg}
                                </div>
                            :
                                null
                        }
                        <form onSubmit={this.onLogin} style={{width: "100%"}}>
                            <div className="form-group">
                                <input id="inputUsername" className="form-control" name="username" placeholder="Username" onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <input id="inputPassword" className="form-control" name="password" placeholder="Password" onChange={this.onChange} type="password"></input>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block" disabled={this.state.loading}>
                                {this.state.loading && <span className="spinner-border spinner-border-sm spinnerStyle" role="status" aria-hidden="true"></span>}
                                SIGN IN
                            </button>
                        </form>
                        <button type="button" className="btn btn-link" onClick={this.onForgotPassword}>Forgot your password?</button>
                        <hr></hr>
                        <button type="button" className="btn btn-outline-primary btn-block" onClick={this.onSignUp}>SIGN UP</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { logIn })(Login);