import React from 'react';
import '../App.css';
import { connect } from 'react-redux';
import {setMarkerLocation,setMarkerLatitude,setMarkerLongitude, refreshMap} from '../redux/actions';
import * as Services from '../utility/services';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LinearProgress from '@material-ui/core/LinearProgress';

import MultipleEditModal from '../components/MultipleEditModal'

class Devices extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedTopicIndex: -1,
            deviceModal: undefined,
            topics: [],
            devices: [],
            selectedRow: undefined,
            loading: false,
            selected: []
        }
        this.modalRef = React.createRef();
        this.rowIndex = 0;
        this.node = undefined
    }
    
    componentDidMount() {
        this.columns = [{
            dataField: 'device_id',
            text: 'ID',
            filter: textFilter()
          },{
            dataField: 'title',
            text: 'Title',
            filter: textFilter()
          }, {
            dataField: 'description',
            text: 'Description',
            filter: textFilter()
          },{
            dataField: 'latitude',
            text: 'Geolocation',
            formatter: (value, row, index, field) => {
                return (<div>{value} {row.longitude}</div>);
            }
          },{
            dataField: 'street',
            text: 'Address',
            formatter: (value, row, index, field) => {
                return value + ", " + row.city;
            }
          },{
            dataField: 'start',
            text: 'Status',
            formatter: (value, row, index, field) => {
                if(new Date() > new Date(row.start) && new Date() < new Date(row.end))
                    return 'Active';
                else if(new Date() > new Date(row.start) && row.end == null)
                    return 'Active';
                else
                    return "Disabled";
            }
          },{
            dataField: 'actions',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        <button type="button" className="btn btn-dark mr-1" onClick={() => this.onClickDetails(row)}>Details</button>
                        <button type="button" className="btn btn-primary mr-1" onClick={() => this.onClickEdit(row)}>Edit</button>
                    </div>
                );
            }
          }
        ];

        this.setState({loading: true});
        Services.GetTopicsCompaniesPublish().then(res => {
            if(res.length){
                let array = res.map(x => x.topic);
                this.setState({topics: array, selectedTopicIndex: 0});
                Services.GetDevicesTopicCompany(this.state.topics[0]._id).then(res => this.setState({devices: res, loading: false}));
            }
            else
                this.setState({loading: false});
        });
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetDevicesTopicCompany(this.state.topics[this.state.selectedTopicIndex]._id).then(res => this.setState({devices: res, loading: false}));
    }

    handleOnSelect = (row, isSelect, rowIndex, e) => {
        let ids = []
        if(e.shiftKey){
            let lower = this.rowIndex
            let upper = rowIndex
            if (rowIndex < this.rowIndex){
                lower = rowIndex
                upper = this.rowIndex
            }
            for(let i=lower; i <= upper; i++){
                ids.push(this.node.table.props.data[i]._id)
            }
        }
        if (isSelect) {
            if(!e.shiftKey){
                this.setState(() => ({
                    selected: [...this.state.selected, row._id]
                }));
            }
            else{
                let selected = [...this.state.selected, ...ids]
                this.setState({selected: [...new Set(selected)] })
            }
                
            this.rowIndex = rowIndex;
        } else {
            if(!e.shiftKey){
                this.setState(() => ({
                    selected: this.state.selected.filter(x => x !== row._id)
                }));
            }
            else {
                let selected = [...this.state.selected].filter( ( el ) => !ids.includes( el ) );
                this.setState({ selected: [...selected] })
            }
            this.rowIndex = rowIndex;
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r._id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    onClickDetails = (device) => {
        this.props.history.push({
            pathname: '/device_details',
            state: {topic: this.state.topics[this.state.selectedTopicIndex], device: device}
        });
    }

    onClickEdit = (device) => {
        this.props.history.push({
            pathname: '/edit_device',
            state: {topic: this.state.topics[this.state.selectedTopicIndex], device: device, value: this.state.selectedTopicIndex}
        });
    }

    onClickDelete = (device) => {
        let result = window.confirm("Do you want to delete device: \n" + device.title);
        if(result){
            
        }
    }

    selectedTopic = (selectedTopicIndex) => {
        this.setState({selectedTopicIndex});
        if(selectedTopicIndex > -1){
            Services.GetDevicesTopicCompany(this.state.topics[selectedTopicIndex]._id).then(res => this.setState({devices: res}));
        }
    }

    render() {
        return (
            <div>
                <MultipleEditModal id="modalMultiEdit" selected={this.state.selected} topics={this.state.topics} topicIndex={this.state.selectedTopicIndex} refresh={this.refresh}/>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Devices</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group">
                            <label htmlFor="selectTopic">Select topic</label>
                            <select className="form-control" id="selectTopic" onChange={(e) => this.selectedTopic(e.target.value)}>
                                {this.state.topics.map((topic, index) => {
                                    return <option key={index} value={index}>{topic.topic}</option>
                                })}
                            </select>
                        </div>
                        {this.state.devices.length ?
                            <div className="my-flex-end form-group">
                                <button type="button" disabled={this.state.selected.length != 0 ? false : true} className="btn btn-primary" data-toggle="modal" data-target="#modalMultiEdit">Multiple Edit</button>
                            </div> 
                            : null
                        }
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        {this.state.devices.length 
                            ? 
                            <BootstrapTable 
                                ref={n => this.node = n}
                                printable 
                                hover 
                                keyField='_id' 
                                data={ this.state.devices } 
                                columns={ this.columns } 
                                selectRow={{
                                    mode: 'checkbox',
                                    clickToSelect: true,
                                    bgColor: '#e0e0e0',
                                    selected: this.state.selected,
                                    onSelect: this.handleOnSelect,
                                    onSelectAll: this.handleOnSelectAll
                                }}
                                pagination={ paginationFactory() }
                                filter={ filterFactory() }
                                //expandRow={ expandRow }
                            /> 
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    markerLocation: state.markerLocation,
    mapProperties: state.mapProperties
});

export default connect(mapStateToProps, { setMarkerLocation, setMarkerLatitude, setMarkerLongitude, refreshMap })(Devices);
