import { 
    LOG_IN,
    LOG_OUT,
    SET_MARKER_LOCATION,
    SET_MARKER_LATITUDE,
    SET_MARKER_LONGITUDE,
    SET_MARKER_RADIUS,
    REFRESH_MAP,
    GET_USER,
    SET_USER
} from '../types';

import * as Services from '../../utility/services';

export const logIn = (username, password) => {
    return {
        type: LOG_IN,
        payload: Services.Login(username, password)
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: user
    }
}

export const getUser = () => {
    return {
        type: GET_USER,
        payload: Services.GetLogUser()
    }
}

export const logOut = () => {
    return {
        type: LOG_OUT
    }
}

export const setMarkerLocation = (latitude, longitude, radius="") => {
    return {
        type: SET_MARKER_LOCATION,
        payload: {latitude, longitude, radius}
    }
}

export const setMarkerLatitude = (latitude) => {
    return {
        type: SET_MARKER_LATITUDE,
        payload: latitude
    }
}

export const setMarkerLongitude = (longitude) => {
    return {
        type: SET_MARKER_LONGITUDE,
        payload: longitude
    }
}

export const setMarkerRadius = (radius) => {
    return {
        type: SET_MARKER_RADIUS,
        payload: radius
    }
}

export const refreshMap = (bool) => {
    console.log("asda");
    return {
        type: REFRESH_MAP,
        payload: bool
    }
}