export const LOG_IN = "LOG_IN"
export const LOG_OUT = "LOG_OUT"
export const LOG_IN_FULFILLED = "LOG_IN_FULFILLED"
export const LOG_IN_REJECTED = "LOG_IN_REJECTED"
export const GET_USER = "GET_USER"
export const GET_USER_REJECTED = "GET_USER_REJECTED"
export const SET_USER = "SET_USER"
export const GET_USER_FULFILLED = "GET_USER_FULFILLED"
export const SET_MARKER_LOCATION = "SET_MARKER_LOCATION"
export const SET_MARKER_LATITUDE = "SET_MARKER_LATITUDE"
export const SET_MARKER_LONGITUDE = "SET_MARKER_LONGITUDE"
export const SET_MARKER_RADIUS = "SET_MARKER_RADIUS"
export const REFRESH_MAP = "REFRESH_MAP"

