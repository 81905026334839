import React from 'react';
import '../App.css';
import { connect } from 'react-redux';
import {setMarkerLocation,setMarkerLatitude,setMarkerLongitude} from '../redux/actions';
import "../../node_modules/bootstrap/dist/js/bootstrap";

import * as Services from '../utility/services';
import * as Utility from '../utility/utility';
import { DivOverlay } from 'leaflet';

class Settings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            username: "",
            phone: "",
            email: "",
            companyID: null,
            companyName: "",
            companyAddress: "",
            companyEmail: "",
            companyPhone: "",
            userId: ""
        }
    }

    componentDidMount() {
        this.onResetChanges();
    }

    onChangePassword = () => {
        if(this.state.newPassword !== "" && this.state.oldPassword !== "" && this.state.confirmPassword !== ""){
            if(this.state.newPassword === this.state.confirmPassword)
                Services.ChangePassword(this.state.userId, this.state.oldPassword, this.state.newPassword)
                    .then(res => {
                        alert(res);
                        this.setState({
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                        });
                    })
                    .catch(err => alert(err))
            else
                alert("New and confirm password not same");
        }
        else{
            alert("Fill all the fields");
        }
        
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSaveChanges = () => {
        Services.ChangeDetails(this.state.firstName, this.state.lastName, this.state.email, this.state.phone)
            .then(res => alert("Update succeeded"))
            .catch(err =>  alert("something went wrong"));
    }

    onSaveChangesCompany = () => {
        Services.ChangeCompanyDetails(this.state.companyName, this.state.companyAddress, this.state.companyEmail, this.state.companyPhone)
            .then(res => alert("Update succeeded"))
            .catch(err => alert("something went wrong"));
    }

    onResetChanges = () => {
        if(Utility.getUserType() === 1){
            Services.GetLogUserCompany().then(res =>{
                let user = res.user;
                let company = res.company;
                this.setState({
                    companyID: company._id, companyName: company.name, companyAddress: company.address, companyPhone: company.phone, companyEmail: company.email,
                    firstName: user.firstName, lastName: user.lastName, phone: user.phone ? user.phone : "", email: user.email ? user.email : "", username: user.userId,
                    userId: user._id
                });
            })
        }
        else{
            Services.GetLogUser().then(res => {
                this.setState({username: res.username, firstName: res.firstName, lastName: res.lastName, phone: res.phone ? res.phone : "", email: res.email ? res.email : "", userId: res._id});
            })
        }
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Settings</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div>
                            <h3>Details</h3>
                            <hr></hr>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="inputFirstName">First name</label>
                                        <input id="inputFirstName" className="form-control" name="firstName" value={this.state.firstName} onChange={this.onChange}></input>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="inputLastName">Last name</label>
                                        <input id="inputLastName" className="form-control" name="lastName" value={this.state.lastName} onChange={this.onChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputEmail">E-mail</label>
                                <input className="form-control" type="text" id="inputEmail" name="email" value={this.state.email} onChange={this.onChange}></input>
                            </div>
                            {Utility.getUserType() < 2
                            ?
                            <div className="form-group">
                                <label htmlFor="inputTelephone">Telephone</label>
                                <input className="form-control" type="text" id="inputTelephone" name="phone" value={this.state.phone} onChange={this.onChange}></input>
                            </div>
                            :
                                null
                            }
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <button className="btn btn-secondary mr-1" onClick={this.onResetChanges}>Reset</button>
                                <button type="submit" className="btn btn-primary" onClick={this.onSaveChanges}>Save</button>
                            </div>
                        </div>
                        {Utility.getUserType() === 1 
                        ?
                        <div>
                            <h3>Company details</h3>
                            <hr></hr>
                            <div className="form-group">
                                <label htmlFor="inputCompanyName">Name</label>
                                <input id="inputCompanyName" className="form-control" name="companyName" value={this.state.companyName} onChange={this.onChange}></input>
                            </div>
        
                            <div className="form-group">
                                <label htmlFor="inputCompanyAddress">Address</label>
                                <input id="inputCompanyAddress" className="form-control" name="companyAddress" value={this.state.companyAddress} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputCompanyEmail">E-mail</label>
                                <input className="form-control" type="text" id="inputCompanyEmail" name="companyEmail" value={this.state.companyEmail} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputCompanyTelephone">Telephone</label>
                                <input className="form-control" type="text" id="inputCompanyTelephone" name="companyPhone" value={this.state.companyPhone} onChange={this.onChange}></input>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <button className="btn btn-secondary mr-1" onClick={this.onResetChanges}>Reset</button>
                                <button type="submit" className="btn btn-primary" onClick={this.onSaveChangesCompany}>Save</button>
                            </div>
                        </div>
                        :
                            null
                        }
                        <div>
                            <h3>Change password</h3>
                            <hr></hr>
                            <div className="form-group row">
                                <label htmlFor="inputCurrent" className="col-sm-2 col-form-label">Current password</label>
                                <div className="col-sm-5">
                                    <input className="form-control" type="password" id="inputCurrent" value={this.state.oldPassword} onChange={(e) => this.setState({oldPassword: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputNew" className="col-sm-2 col-form-label">New password</label>
                                <div className="col-sm-5">
                                    <input className="form-control" type="password" id="inputNew" value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputConfirm" className="col-sm-2 col-form-label">Confirm password</label>
                                <div className="col-sm-5">
                                    <input className="form-control" type="password" id="inputConfirm" value={this.state.confirmPassword} onChange={(e) => this.setState({confirmPassword: e.target.value})}></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2"></label>
                                <div className="col-sm-5">
                                    <button type="submit" className="btn btn-primary" onClick={this.onChangePassword}>Change password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    markerLocation: state.markerLocation
});

export default connect(mapStateToProps, { setMarkerLocation, setMarkerLatitude, setMarkerLongitude })(Settings);