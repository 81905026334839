import React from 'react';
import '../App.css';
import Map from '../components/Map';
import * as Services from '../utility/services';
import Long from 'long';
import {getPropertyWithType} from '../utility/functions';

import LinearProgress from '@material-ui/core/LinearProgress';

export default class DeviceDetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sensorValues: undefined,
        }
    }

    componentDidMount(){
        let device = this.props.location.state.device;
        let topic = this.props.location.state.topic;
        let path = device.company.mqtt.id + "/";
        let topic_split = device.topic.topic.split('/');
        topic_split.forEach(element => {
            if(element !== "x")
                path += element + "/";
            else
                path += device.device_id + "/";
        });
        let paths = [];
        topic.properties.forEach(property => {
            paths.push(path + getPropertyWithType(property))
        })
        
        Services.GetMsgsDevice(paths).then(res => this.parseSensorValues(res)).catch(err => console.log(err))
    }

    parseSensorValues = (msgs) => {
        console.log(msgs)
        if(msgs.length > 0){
            let result = [];
            msgs.forEach(msg => {
                result.push(
                    <tr key={msg._id}>
                        <th scope="row">{msg.property}</th>
                        <td>{msg.value}</td>
                        <td>{new Date(new Long.fromNumber(msg.ts).getHighBits() * 1000).toLocaleString()}</td>
                    </tr>
                );
            });
    
            let table = (
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                            <th>Timestamp</th>
                        </tr>
                        {result}
                    </tbody>
                </table>
            );
            this.setState({sensorValues: table})
        }
        else {
            this.setState({sensorValues: ["No sersor values."]})
        }
    }

    getDeviceDetails = (device) => {
        let array = [];

        for(let key in device) {
            if(Object.prototype.hasOwnProperty.call(device, key)){
                let value = device[key];
                if(key === "topic")
                    array.push(<p key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value.topic + " " + value._id}</p>)
                else if(key === "company")
                    array.push(<p key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value.name + " " + value._id}</p>)
                else
                    array.push(<p key={key}><b>{key.toLocaleUpperCase() + ": "}</b>{value}</p>)
            }
        }

        let table = (
            <table className="table table-striped" style={{marginTop: 20}}>
                <tbody>
                    <tr>
                        <th scope="row">ID</th>
                        <td>{device.device_id}</td>
                    </tr>
                    {
                        device.tags.length > 0 ?
                        <tr>
                            <th scope="row">Tags</th>
                            <td>{device.tags.map((x, index) => (index != 0 ? ", " : "") + x)}</td>
                        </tr>
                        : null
                    }
                    <tr>
                        <th scope="row">Topic</th>
                        <td>{device.topic.topic}</td>
                    </tr>
                    <tr>
                        <th scope="row">Title</th>
                        <td>{device.title}</td>
                    </tr>
                    <tr>
                        <th scope="row">Description</th>
                        <td>{device.description}</td>
                    </tr>
                    <tr>
                        <th scope="row">Geolocation</th>
                        <td>{device.latitude + ", " + device.longitude}</td>
                    </tr>
                    <tr>
                        <th scope="row">Address</th>
                        <td>{device.street + ", " + device.city + ", " + device.country}</td>
                    </tr>
                    <tr>
                        <th scope="row">Time interval</th>
                        <td>{device.start + " - " + device.end}</td>
                    </tr>
                </tbody>
            </table>
        );

        let radius_int = ""
        try{
            if(device.radius != "" && device.radius != undefined)
                radius_int = parseInt(device.radius)
        } catch(e){
            radius_int = ""
        }
        const details = (<div className="col-lg-4" key={"deviceDetails" + device.id}>{table}</div>);
        const map = (<Map latitude={device.latitude} longitude={device.longitude} radius={radius_int} marker={true} zoom={14} topic={{id: this.props.location.state.topic._id, mapMarkerStyle: this.props.location.state.topic.mapMarkerStyle}}/>);
        const wrapper = (<div className="col-lg-8" key={"mapDeviceDetails" + device.id}>{map}</div>);

        return [details,wrapper];
    }

    render() {
        console.log(this.props.location.state.device)
        return (
            <div style={{marginTop: "50px", marginBottom: "50px"}}>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h1 className="display-4">Device details</h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row"> 
                        {this.props.location.state.device ? this.getDeviceDetails(this.props.location.state.device) : null}
                    </div>
                    {this.state.sensorValues !== undefined 
                        ? 
                            this.state.sensorValues
                        :
                            <div>
                                <LinearProgress />
                            </div> 
                    }
                </div>
            </div>
        );
    }
}