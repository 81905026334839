import React from 'react';
import * as Services from '../../utility/services';
import LinearProgress from '@material-ui/core/LinearProgress';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

const headerStyle = {fontWeight: 'bold', backgroundColor: "#eee"};

export default class Logs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            logs: []
        }

        this.columns = [{
            dataField: 'method', 
            text: 'Method', 
            filter: textFilter()
          },{
            dataField: 'url', 
            text: 'Url + Referer', 
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        <div>
                            <div><b>Url</b></div>
                            {row.url}
                        </div>
                        <div>
                            <div><b>Referer</b></div>
                            {row.header.referer
                        }</div>
                    </div>
                );
            }
          },{
            dataField: 'user',
            text: 'User',
            formatter: (value, row, index, field) => {
                return this.objectDetails(value);
            }
          },{
            dataField: 'body',
            text: 'Body',
            formatter: (value, row, index, field) => {
                return this.objectDetails(value);
            }
          },{
            dataField: 'createdAt', 
            text: 'Created', 
          }
        ];
    }

    componentDidMount(){
        this.refresh();
    }

    refresh = () => {
        this.setState({loading: true})
        Services.GetLogs().then(res => {
            this.setState({logs: res, loading: false})
        }).catch(err => {
            console.log(err)
            this.setState({loading: false})
        })
    }

    objectDetails = (object) => {
        let array = [];

        for(let key in object) {
            if(Object.prototype.hasOwnProperty.call(object, key)){
                let value = object[key];
                if(typeof value !== "object")
                    array.push(<div key={key}><b>{key + ": "}</b>{value + " "}</div>)
                else
                    array.push(<div key={key}><b>{key + ": "}</b>{this.objectDetails(value)}</div>)
            }
        }
        return array;
    }

    render(){
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Logs</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group my-flex-end">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.logs} 
                            columns={ this.columns } 
                            filter={ filterFactory() }
                            pagination={ paginationFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                        {/*<TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={headerStyle}>Method</TableCell>
                                        <TableCell style={headerStyle}>Url</TableCell>
                                        <TableCell style={headerStyle}>Referer</TableCell>
                                        <TableCell style={headerStyle}>User</TableCell>
                                        <TableCell style={headerStyle}>Body</TableCell>
                                        <TableCell style={headerStyle}>DateTime</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.logs.map(row => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">{row.method}</TableCell>
                                        <TableCell>{row.url}</TableCell>
                                        <TableCell>{row.header.referer}</TableCell>
                                        <TableCell>{this.objectDetails(row.user)}</TableCell>
                                        <TableCell>{this.objectDetails(row.body)}</TableCell>
                                        <TableCell>{row.createdAt}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                                </TableContainer>*/}
                        
                    </div>
                </div>
            </div>
        );
    }
}