import React from 'react';

export default class Refused extends React.Component {
    render() {
        return (
            <div className="App">
                <h1>Account was refused by admin of company</h1>
            </div>
        );
    }
}