import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Redirect, Link, NavLink, Switch } from 'react-router-dom'
import Profile from './pages/Profile'
import Login from './pages/Login'
import Devices from './pages/Devices'
import {connect} from 'react-redux';
import Settings from './pages/Settings';
import SignUp from './pages/SignUp';
import DeviceDetails from './pages/DeviceDetails';
import * as Service from './utility/services';
import * as Utility from './utility/utility';
import Topics from './pages/Admin/Topics';
import Companies from './pages/Admin/Companies';
import TopicCompany from './pages/Admin/TopicCompany';
import Import from './pages/Admin/Import';
import ImportDevices from './pages/ImportDevices';
import Pending from './pages/Pending';
import Refused from './pages/Refused';
import Users from './pages/Users';
import TopicCompanyUser from './pages/TopicCompanyUser';
import TopicsUser from './pages/TopicsUser';

//import globalTranslations from './utility/translations.json';
//import { renderToStaticMarkup } from "react-dom/server";
//import { withLocalize } from "react-localize-redux";
import NotFound from './pages/NotFound';
import NotAuthorzied from './pages/NotAuthorized';
import EditCompany from './pages/Admin/EditCompany';
import MQTT from './pages/MQTT';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Admins from './pages/Admin/Admins';

import {gdprCookies} from './assets/text.js';
import CookiesPolicy from './pages/CookiesPolicy';
import TermsConditions from './pages/TermsConditions';
import Logs from './pages/Admin/Logs';

import AddEditDevice from './pages/AddEditDevice';
import AddEditTopic from './pages/Admin/AddEditTopic';
import AdminMQTT from './pages/Admin/AdminMQTT';
import RegisterTopics from './pages/RegisterTopics';

const UserTypes = require('./utility/userTypes.json');

class NavBar extends React.Component {
  onLogout = () => {
    Service.Logout();
  }

  componentDidUpdate(){
    Service.GetLogUser().catch(err => window.location = "/login");
  }

  getLayout = () => {
    let type = Utility.getUserType();
    let accepeted = Utility.isAccepted();
    if(accepeted === 0 || accepeted === -1){
      return (<div className="navbar-nav mr-auto"></div>);
    }
    else if(type === UserTypes.admin || type === UserTypes.super_admin){
      return (
        <div className="navbar-nav mr-auto">
          <NavLink to="/companies" className="nav-item nav-link">Companies</NavLink>
          {Utility.getUserType() === UserTypes.super_admin ? <NavLink to="/admins" className="nav-item nav-link">Admins</NavLink> : null}
          <NavLink to="/topics" className="nav-item nav-link">Topics</NavLink>
          <NavLink to="/add_topic" className="nav-item nav-link">Add Topic</NavLink>
          <NavLink to="/topic_company" className="nav-item nav-link">Topic - Company</NavLink>
          <NavLink to="/import" className="nav-item nav-link">Import</NavLink>
          <NavLink to="/logs" className="nav-item nav-link">Logs</NavLink>
          <NavLink to="/admin_mqtt" className="nav-item nav-link">MQTT</NavLink>
        </div>
      );
    }
    else {
      return (
        <div className="navbar-nav mr-auto">
          <NavLink to="/profile" className="nav-item nav-link">Profile</NavLink>
          <NavLink to="/mqtt" className="nav-item nav-link">MQTT</NavLink>
          {Utility.getUserType() === UserTypes.admin_company ? <NavLink to="/users" className="nav-item nav-link">Users</NavLink> : null}
          <NavLink to="/devices" className="nav-item nav-link">Devices</NavLink>
          <NavLink to="/add_device" className="nav-item nav-link">Add Device</NavLink>
          <NavLink to="/topics_user" className="nav-item nav-link">Topics</NavLink>
          <NavLink to="/topic_company_user" className="nav-item nav-link">Topic - Company</NavLink>
          <NavLink to="/import_devices" className="nav-item nav-link">Import</NavLink>
        </div>
      );
    }
  }

  getDropdown = () => {
    let accepeted = Utility.isAccepted();
    if(accepeted === 0 || accepeted === -1){
      return (
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <div className="dropdown-item" onClick={this.onLogout}>Logout</div>
        </div>
      );
    }
    else{
      return(
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <NavLink to="/settings" className="dropdown-item">Settings</NavLink>
          <div className="dropdown-item" onClick={this.onLogout}>Logout</div>
        </div>
      );
    }
  }

  render(){
      let layout = this.getLayout();
      let dropdown = this.getDropdown();
      return (
          <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
              <Link to={(Utility.getUserType() === UserTypes.admin || Utility.getUserType() === UserTypes.super_admin) ? "/companies" : "/profile"} className="navbar-brand">{window.REACT_APP_TITLE == "UMPlatforma" ?
                            <h2><b className='blue'>UM</b>Platforma</h2>
                        :
                            <h2>{window.REACT_APP_TITLE}</h2>
                        }</Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                {layout}
                <div className="nav-item dropdown">
                  <a className="dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color: "white"}}>
                    Welcome, <span style={{color: "white"}}>{Utility.getUserFirstName() + " " + Utility.getUserLastName()} ({Utility.getUsername()})</span>
                  </a>
                  {dropdown}
                </div>
                {/*<button type="button" className="btn btn-dark" onClick={this.onLogout}>Logout</button>*/}
              </div>
              
          </nav>
      );
  }
}

class CustomeRoute extends React.Component {
  render(){
    const {component: Component, ...other} = this.props;
    return(
      <Route {...other} render={(props) => {
        if(this.props.user.username !== undefined){ //undefined is when page is refreshed or first load
          if(this.props.user.username !== null){
            if(Utility.isAccepted() === 1){
              if(this.props.user.type === this.props.type || (this.props.user.type === 1 && this.props.type === 0) || (this.props.user.type === 3 && this.props.type === 2) || this.props.type === undefined){
                return (<div>
                  <NavBar />
                  <Component {...props} {...this.props.componentProps} />
                </div>)
              }
              else
                return <Redirect to="/not-authorized" />;
            }
            else if(Utility.isAccepted() === 0)
                return(<Redirect to="/pending" />)
            else
                return(<Redirect to="/refused" />)
          }
          else{
            return(<Redirect to="/login" />);
          }
        }
        else{
          return null;
        }
      }} />
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const ConnectedCustomRoute = connect(mapStateToProps, null)(CustomeRoute);

class CookieRoute extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      cookiesBanner: true
    }
  }

  render(){
    const {component: Component, ...other} = this.props;
    return(
      <Route {...other} render={(props) => {
        return(
          <div>
            <Component {...props} />
            <div className="cookiesBanner" style={{visibility: this.state.cookiesBanner ? "visible" : "collapse"}}>
              <div className="row">
                <div className="col" style={{alignItems: "center", display: "flex"}}>
                  {gdprCookies}
                </div>
                <div style={{paddingRight: "20px"}}>
                  <button className="btn btn-link" onClick={() => document.location.href="/cookies_policy"}>Cookies Policy</button>
                  <button className="btn btn-sm btn-primary" onClick={() => this.setState({cookiesBanner: false})}>OK</button>
                </div>
              </div>
            </div>
          </div>
        );
      }} />
    )
  }
}

function getFaviconEl() {
  let favicon = document.getElementById("favicon");
  favicon.href = undefined//process.env.REACT_APP_LOGO //"E:\\ReactWebApp\\UMPlatforma\\frontend\\logoTest.png" //"https://www.bass.si/wp-content/uploads/2018/01/cropped-BassLogo-1-1-32x32.png";
}

class App extends React.Component {
  /*constructor(props) {
    super(props);
    this.props.initialize({
        languages: [
            { name: "sl", code: "slo" },
            { name: "en", code: "en" }
        ],
        translation: globalTranslations,
        options: { renderToStaticMarkup }
    });
  }*/
  componentDidMount(){
    //getFaviconEl()
  }

  render() {
    Utility.isUserLogged(); //solves refresh problem for user login
    return (
      <div>
        <Router>
          <div>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <CookieRoute exact path="/login" component={Login} />
              <CookieRoute exact path="/sign_up" component={SignUp} />
              <Route exact path="/cookies_policy" component={CookiesPolicy} />
              <Route exact path="/terms_conditions" component={TermsConditions} />
              <Route exact path="/forgot_password" component={ForgotPassword} />
              <Route path="/reset" component={ResetPassword} />
              <ConnectedCustomRoute exact component={Profile} type={0} path="/profile" />
              <ConnectedCustomRoute exact component={Devices} type={0} path="/devices" />
              <ConnectedCustomRoute exact component={AddEditDevice} componentProps={{type: "add"}} type={0} path="/add_device" />
              <ConnectedCustomRoute exact component={AddEditDevice} componentProps={{type: "edit"}} type={0} path="/edit_device" />
              <ConnectedCustomRoute exact component={DeviceDetails} type={0} path="/device_details" />
              <ConnectedCustomRoute exact component={Settings} path="/settings" />
              <ConnectedCustomRoute exact component={ImportDevices} type={0} path="/import_devices" />
              <ConnectedCustomRoute exact component={MQTT} type={0} path="/mqtt" />
              <ConnectedCustomRoute exact component={TopicCompanyUser} type={0} path="/topic_company_user" />
              <ConnectedCustomRoute exact component={TopicsUser} type={0} path="/topics_user" />
              <ConnectedCustomRoute exact component={Users} type={1} path="/users" />
              <ConnectedCustomRoute exact component={Topics} type={2} path="/topics" />
              <ConnectedCustomRoute exact component={RegisterTopics} type={0} path="/register_topics" />
              <ConnectedCustomRoute exact component={AddEditTopic} componentProps={{type: "add"}} type={2} path="/add_topic" />
              <ConnectedCustomRoute exact component={AddEditTopic} componentProps={{type: "edit"}} type={2} path="/edit_topic" />
              <ConnectedCustomRoute exact component={AddEditTopic} componentProps={{type: "add"}} type={0} path="/request_topic" />
              <ConnectedCustomRoute exact component={Companies} type={2} path="/companies" />
              <ConnectedCustomRoute exact component={EditCompany} type={2} path="/edit_company" />
              <ConnectedCustomRoute exact component={TopicCompany} type={2} path="/topic_company" />
              <ConnectedCustomRoute exact component={Import} type={2} path="/import" />
              <ConnectedCustomRoute exact component={Admins} type={3} path="/admins" />
              <ConnectedCustomRoute exact component={Logs} type={2} path="/logs" />
              <ConnectedCustomRoute exact component={AdminMQTT} type={2} path="/admin_mqtt" />
              <Route exact path="/pending" render={() => Utility.isAccepted() === 0 ? <div><NavBar/><Pending /></div> : <Redirect to="/login" />}/>
              <Route exact path="/refused" render={() => Utility.isAccepted() === -1 ?  <div><NavBar/><Refused /></div> : <Redirect to="/login" />}/>
              <Route path="/not-authorized" component={NotAuthorzied}/>
              <Route component={NotFound}/>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}


export default App;