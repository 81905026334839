import React from 'react';
import * as Services from '../../utility/services';
import {breakUniqueMongoDBError} from '../../utility/functions';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LinearProgress from '@material-ui/core/LinearProgress';

export default class Admins extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            admins: [],
            addAdminToggle: false,
            firstName: "",
            lastName: "",
            username: "",
            password: "",
            email: "",
            loading: false
        }

        this.columns = [{
            dataField: 'firstName', 
            text: 'First name', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'lastName', 
            text: 'Last name', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          }, {
            dataField: 'username', 
            text: 'Username', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'email', 
            text: 'E-mail', 
            filter: textFilter(),
            sort: true,
            sortCaret: (order, column) => {
                return null;
            }
          },{
            dataField: 'accepted',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            formatter: (value, row, index, field) => {
                return(
                    <div>
                        <button type="button" className="btn btn-danger mr-1" onClick={() => this.onDeleteAdmin(row)}>Delete</button> 
                    </div>
                )
            }
          },{
            dataField: 'createdAt',
            text: 'CreatedAt',
            type: "date",
            hidden: true,
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            sort: true
          }
        ];
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetAdmins().then(res => {
            this.setState({admins: res, loading: false})
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        });
    }

    onToggleAddAdmin = () => {
        console.log("tu");
        if(this.state.addAdminToggle){
            this.setState({
                addAdminToggle: !this.state.addAdminToggle,
                username: "",
                password: "",
                firstName: "",
                lastName: "",
                email: ""
            });
        }
        else
            this.setState({addAdminToggle: !this.state.addAdminToggle});
        
    }

    onAddAdmin = () => {
        if(this.state.lastName !== "" && this.state.firstName !== "" && this.state.username !== "" && this.state.email !== "" && this.state.password !== "")
            Services.AddAdmin({username: this.state.username, password: this.state.password, firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email})
                .then(() => {
                    this.setState({
                        addAdminToggle: !this.state.addAdminToggle,
                        username: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                        email: ""
                    });
                    this.refresh();
                }).catch(err => {
                    if(err.response){
                        if(err.response.data){
                            let msg = breakUniqueMongoDBError(err.response.data);
                            alert(msg);
                        }
                        else
                            alert("Something went wrong!");
                    }
                    else
                        alert("Something went wrong!");
                    
                
                });
        else{
            alert("Please fill out all the fields!");
        }
    }

    onDeleteAdmin = (user) => {
        let r = window.confirm('Are you sure you want to delete user "' + user.firstName + " " + user.lastName + " (" + user.username + ")");
        if(r){
            Services.DeleteUser(user._id).then(() => {
                this.refresh()
            }).catch((err) => {
                console.log(err); 
                alert("Something went wrong!")
            })
        }
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Admins</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group">
                            <button type="button" className="btn btn-primary" onClick={() => this.onToggleAddAdmin()}>Add admin</button>
                        </div>
                        {this.state.addAdminToggle
                        ?   
                            <div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Username" onChange={e => this.setState({username: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="FirstName" onChange={e => this.setState({firstName: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="LastName" onChange={e => this.setState({lastName: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Email" onChange={e => this.setState({email: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Password" onChange={e => this.setState({password: e.target.value})}/>
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.onAddAdmin()}>Add</button>
                                </div>
                            </div>
                        :
                            null
                        }
                        <div className="form-group my-flex-end">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>

                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }

                        <BootstrapTable 
                            ref={ n => this.table = n }
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.admins} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                    </div>
                </div>
            </div>
        );
    }
}