import React from 'react'; 
import CryptoJS from "crypto-js";
import * as Services from '../../utility/services';

export default class EditCompany extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
            company: null,
            ip: "",
            port: "",
            username: "",
            password: "",
            companyKey: "",
            id: ""
        }
    }

    componentDidMount() {
        if(this.props.location.state){
            if(this.props.location.state.user){
                let user = this.props.location.state.user;
                let company = user.company;
                company.mqtt.password = CryptoJS.AES.decrypt(company.mqtt.password, CryptoJS.MD5(company._id).toString(CryptoJS.enc.Base64)).toString(CryptoJS.enc.Utf8);
                this.setState({user, company: company, ip: company.mqtt.ip, port: company.mqtt.port, username: company.mqtt.username, password: company.mqtt.password, companyKey: company.companyKey, id: company.mqtt.id});    
            }
        }
        else{
            this.props.history.push("/users");
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onResetChangesMQTT = () => {
        let mqtt = this.state.company.mqtt;
        this.setState({ip: mqtt.ip, port: mqtt.port, username: mqtt.username, password: mqtt.password});
    }

    onSaveChangesMQTT = () => {
        Services.ChangeMqttData(this.state.company._id, {id: this.state.id, ip: this.state.ip, port: this.state.port, username: this.state.username, password: this.state.password}).then(res => {
            Services.GetCompanyById(this.state.company._id).then(res => {
                res.mqtt.password = CryptoJS.AES.decrypt(res.mqtt.password, CryptoJS.MD5(res._id).toString(CryptoJS.enc.Base64)).toString(CryptoJS.enc.Utf8);
                this.setState({company: res});
            }).catch(err => console.log(err));
            alert("MQTT data changed");
        }).catch(err => {});
    }

    onSaveChangesKey = () => {
        Services.ChangeKey(this.state.company._id, this.state.companyKey).then(res => {
            Services.GetCompanyById(this.state.company._id).then(res => {
                res.mqtt.password = CryptoJS.AES.decrypt(res.mqtt.password, CryptoJS.MD5(res._id).toString(CryptoJS.enc.Base64)).toString(CryptoJS.enc.Utf8);
                this.setState({company: res});
            }).catch(err => console.log(err));
            alert("Company Key changed");
        }).catch(err => {});
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Edit company</h1>
                            {this.state.user !== null
                                ?
                                    <p className="lead">{this.state.user.company.name + " (" + this.state.user.firstName + " " + this.state.user.lastName + ")"}</p>
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className="container">
                        <h3>MQTT data</h3>
                        <div>
                            <div className="form-group">
                                <label htmlFor="id">ID</label>
                                <input className="form-control" id="id" name="id" placeholder="ID" value={this.state.id} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="ip">IP</label>
                                        <input className="form-control" id="ip" name="ip" placeholder="IP" value={this.state.ip} onChange={this.onChange}></input>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="port">Port</label>
                                        <input className="form-control" id="port" name="port" placeholder="Port" value={this.state.port} onChange={this.onChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="user">Username</label>
                                        <input className="form-control" id="user" name="username" placeholder="Username" value={this.state.username} onChange={this.onChange}></input>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="pass">Pasword</label>
                                        <input className="form-control" id="pass" name="password" placeholder="Pasword" value={this.state.password} onChange={this.onChange} type="password" onMouseOver={(e) => e.target.type = "text"} onMouseOut={(e) => e.target.type = "password"}></input>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <button className="btn btn-secondary mr-1" onClick={this.onResetChangesMQTT}>Reset</button>
                                <button type="submit" className="btn btn-primary" onClick={this.onSaveChangesMQTT}>Save</button>
                            </div>
                        </div>
                        <hr/>
                        <h3>Company KEY for Sign Up</h3>
                        <div className="form-group">
                            <input className="form-control" name="companyKey" value={this.state.companyKey} onChange={this.onChange}></input>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <button className="btn btn-secondary mr-1" onClick={() => this.setState({companyKey: this.state.user.company.companyKey})}>Reset</button>
                            <button type="submit" className="btn btn-primary" onClick={this.onSaveChangesKey}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}