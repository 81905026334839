import React, { Fragment } from 'react';
import * as Services from '../../utility/services';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

var groupBy = function (arr, criteria) {
	return arr.reduce(function (obj, item) {
		// Check if the criteria is a function to run on the item or a property of it
		var key = typeof criteria === 'function' ? criteria(item) : item[criteria];
		// If the key doesn't exist yet, create it
		if (!obj.hasOwnProperty(key)) {
			obj[key] = [];
		}
		// Push the value to the object
		obj[key].push(item);
		// Return the object to the next item in the loop
		return obj;
	}, {});
};

export default class TopicCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            companies: [],
            topicCompanies: [],
            selectRights: "publish",
            selectCompany: -1,
            selectTopic: -1,
            loading: false,
            grouped: [],
            searchCompanyString: "",
            searchTopicString: "",
            pending: []
        }

        this.typingTimeout = 0;
        this.typing = false;

        this.columns = [{
            dataField: 'topic.topic', 
            text: 'Topic'
          },{
            dataField: 'company.name',
            text: 'Company'
          },{
            dataField: 'type', 
            text: 'Rights'
          },{
            dataField: 'actions',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        <button type="button" className="btn btn-success mr-1" onClick={() => this.onAcceptPending(row._id)}>Accept</button>
                        <button type="button" className="btn btn-danger mr-1" onClick={() => this.onDeclinePending(row._id)}>Decline</button>
                    </div>
                )
            }
          }
        ];
    }

    componentDidMount() {
        this.getPending()

        this.setState({loading: true});
        Services.GetTopicsCompanies().then(res => {
            let grouped = groupBy(res, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
            this.setState({topicCompanies: res, loading: false, grouped})
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        });
        Services.GetTopics().then(res => {
            let array = res.filter(x => x.pending == undefined)
            this.setState({topics: array})
        });
        Services.GetCompanies().then(res => {
            let array = res.filter(x => x.accepted === 1);
            this.setState({companies: array})
        });
    } 

    getPending = () => {
        Services.GetPendingPermission().then(res => {
            this.setState({pending: res})
        }).catch(err => console.log(err))
    }

    onAcceptPending = (id) => {
        Services.AcceptPendingPermission(id).then(res => {
            this.getPending()
        }).catch(err => console.log(err))
    }

    onDeclinePending = (id) => {
        Services.DeletePendingPermission(id).then(res => {
            this.getPending()
        }).catch(err => console.log(err))
    }
    
    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetTopicsCompanies().then(res => {
            let grouped = groupBy(res, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
            this.setState({topicCompanies: res, loading: false, grouped})
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        });
    }

    addTopicCompany = () => {
        if(this.state.selectCompany !== -1 && this.state.selectTopic !== -1){
            let company = this.state.companies[this.state.selectCompany];
            let topic = this.state.topics[this.state.selectTopic];
            let newTopicCompany = {
                company: company._id,
                topic: topic._id,
                type: this.state.selectRights
            }
            Services.AddTopicCompany(newTopicCompany).then(res => {
                this.refresh();
            })
            .catch(err => {
                console.log(err);
                alert("Link already exists");
            })
        }
        else
            alert("Please select.");
    }  

    onRemove = (id) => {
        Services.RemoveTopicCompany(id).then(res => { this.refresh(); });
    }

    deleteConnection = (doc) => {
        let r = window.confirm("Are you sure you want to delete: " + doc.topic.topic + " - " + doc.company.name + " type " + doc.type);
        if(r){
            Services.RemoveTopicCompany(doc._id).then(res => {
                let topicCompanies = [...this.state.topicCompanies];
                let index = topicCompanies.indexOf(doc);
                topicCompanies.splice(index, 1);
                let grouped = groupBy(topicCompanies, element => element.company._id);
                for(let key in grouped){
                    let company = groupBy(grouped[key], x => x.topic._id)
                    grouped[key] = company;
                }
                this.setState({topicCompanies: topicCompanies, grouped})
            });
        }
    }

    createConnection = (companyId, topicId, type) => {
        let newTopicCompany = {
            company: companyId,
            topic: topicId,
            type: type
        }
        Services.AddTopicCompany(newTopicCompany)
        .then(res => this.refresh())
        .catch(err => console.log(err))
    }

    mapGrouped = () => {
        let grouped = this.state.grouped;

        if(this.state.searchCompanyString !== "" || this.state.searchTopicString !== ""){
            let searchCompanyString = this.state.searchCompanyString.toLowerCase();
            let searchTopicString = this.state.searchTopicString.toLowerCase();

            let filter = null;
            if(this.state.searchCompanyString !== "" && this.state.searchTopicString !== "")
                filter = this.state.topicCompanies.filter(x => x.topic.topic.toLowerCase().includes(searchTopicString) && x.company.name.toLowerCase().includes(searchCompanyString));
            else if(this.state.searchCompanyString !== "")
                filter = this.state.topicCompanies.filter(x => x.company.name.toLowerCase().includes(searchCompanyString));
            else if(this.state.searchTopicString !== "")
                filter = this.state.topicCompanies.filter(x => x.topic.topic.toLowerCase().includes(searchTopicString));

            grouped = groupBy(filter, element => element.company._id);
            for(let key in grouped){
                let company = groupBy(grouped[key], x => x.topic._id)
                grouped[key] = company;
            }
        }

        let group = null;
        let result = [];
        let companyName = "";
        let topicName = "";
        let tableBody = [];
        let subGroup = null;
        let publish = false;
        let subscribe = false;

        for(let key in grouped){
            group = grouped[key];
            tableBody = [];
            for(let topicKey in group){
                subGroup = group[topicKey];
                publish = null;
                subscribe = null;

                companyName = subGroup[0].company.name;
                topicName = subGroup[0].topic.topic;

                subGroup.forEach(element => {
                    if(element.type === "publish")
                        publish = <Checkbox checked={true} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.deleteConnection(element)}/>;
                    else 
                        subscribe = <Checkbox checked={true} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.deleteConnection(element)}/>;
                });
                tableBody.push(
                    <TableRow hover role="checkbox" tabIndex={-1} key={key + "_" + topicKey}>
                        <TableCell align={'center'}>
                            {topicName}
                        </TableCell>
                        <TableCell align={'center'}>
                            {publish ? publish : <Checkbox checked={false} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.createConnection(key, topicKey, "publish")}/>}
                        </TableCell>
                        <TableCell align={'center'}>
                            {subscribe ? subscribe : <Checkbox checked={false} color="primary" style={{padding: 0, color: "black"}} onChange={e => this.createConnection(key, topicKey, "subscribe")}/>}
                        </TableCell>
                    </TableRow>
                );
            }
            result.push(
                <Fragment key={key}>
                    <TableHead style={{backgroundColor: "#ccc"}} key={"tableHead" + key}>
                        <TableRow>
                            <TableCell align={'left'} style={{backgroundColor: "#ddd", fontWeight: 'bold', width: "50%"}}>
                                {companyName}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Publish"}
                            </TableCell>
                            <TableCell align={'center'} style={{backgroundColor: "#ddd"}}>
                                {"Subscribe"}
                            </TableCell>
                            {/*
                            <TableCell align={'right'} style={{backgroundColor: "#ddd", width: '1px'}}>
                                <button className="btn btn-sm btn-link"><AddIcon/></button>
                            </TableCell>
                            */} 
                        </TableRow>
                    </TableHead>
                    <TableBody key={"tableBody" + key}>{tableBody}</TableBody>
                </Fragment>
            );
        }
        return result;
    }

    searchChange = (e, variable) => {
        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        let value = e.target.value;
        this.typingTimeout = setTimeout(() => {
            this.setState({[variable]: value});
        }, 400);
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Topic - Company</h1>
                        </div>
                    </div>
                    <div className="container">
                        {this.state.pending.length > 0 &&
                        <div>
                        <h3>Pending requests</h3>
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.pending} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        />
                        <hr/>
                        </div>
                        }
                        <div className="row align-items-end">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="selectTopic">Select Topic</label>
                                    <select className="form-control" id="selectTopic" onChange={this.onChange}>
                                        <option value={-1}>Select topic</option>
                                        {this.state.topics.map((topic, index) => (
                                            <option key={topic._id} value={index}>{topic.topic + " ("+ topic._id +")"}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="selectCompany">Select Company</label>
                                    <select className="form-control" id="selectCompany" onChange={this.onChange}>
                                        <option value={-1}>Select Company</option>
                                        {this.state.companies.map((company, index) => (
                                            <option key={company._id} value={index}>{company.name + " ("+ company._id +")"}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="selectRights">Rights</label>
                                    <select className="form-control" id="selectRights" onChange={this.onChange}>
                                        <option value="publish">publish</option>
                                        <option value="subscribe">subscribe</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary" onClick={this.addTopicCompany}>Add</button>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.refresh}>Refresh</button>
                        </div>
                        <div className="row" style={{marginBottom: 20}}>
                            <div className="col-sm">
                                <input className="form-control" type="text" placeholder="Search Company" onChange={e => this.searchChange(e, 'searchCompanyString')}/>
                            </div>
                            <div className="col-sm">
                                <input className="form-control" type="text" placeholder="Search Topic" onChange={e => this.searchChange(e, 'searchTopicString')}/>
                            </div>
                        </div>
                        
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <div className="row">
                            <div className="col-sm">
                                <Paper style={{width: "100%", border: "1px solid #ddd"}}>
                                    <TableContainer style={{maxHeight: 440}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            {this.mapGrouped(this.state.searchString)}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}