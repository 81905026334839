import React from 'react';
import '../App.css';
import "../../node_modules/bootstrap/dist/js/bootstrap";
import * as Services from '../utility/services';
import CryptoJS from "crypto-js";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


class MQTT extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mqtt: null,
            mqttShow: false,
            password: "",
            mqttId: "",
            activeTab: "pub",
            selectTopic: "",
            selectDeviceID: "",
            selectProperty: "",
            topicsList: [],
            deviceList: []
        }
    }

    getMqttDetails = () => {
        if(!this.state.mqttShow){
            Services.GetLogUserCompany()
            .then(res => {
                console.log(res)
                let mqtt = res.company.mqtt;
                mqtt.password = CryptoJS.AES.decrypt(mqtt.password, CryptoJS.MD5(res.company._id).toString(CryptoJS.enc.Base64)).toString(CryptoJS.enc.Utf8);
                this.setState({mqtt, mqttId: mqtt.id, mqttShow: true});
            })
            .catch(err => console.log(err));

            Services.GetDevicesCompany().then(res => {
                let topics = res.map(device => (device.topic)).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);
                this.setState({topicsList: topics, deviceList: res})
            }).catch(err => console.log(err))
        }
    }

    onResetId = () => {
        this.setState({mqttId: this.state.mqtt.id});
    }

    onChange = (e) => {
        this.setState({mqttId: e.target.value})
    } 

    onSaveId = () => {
        Services.ChangeMqttId(this.state.mqttId).then(() => {
            let mqtt = this.state.mqtt;
            mqtt.id = this.state.mqttId;
            this.setState({mqtt});
            alert("MQTT id changed")
        }).catch(() => {})
    }

    getDetails = (object, header, props) => {
        let array = [];
        props.forEach((prop, index) => {
            let value = object[prop];
            array.push(
                <tr key={prop + object._id}>
                    <th scope="row">{header[index]}</th>
                    <td>{value}</td>
                    {/*prop !== "id" 
                        ? <td>{value}</td> 
                        : (Utility.getUserType() === UserType.admin_company
                            ?
                            <td>
                                <div className="input-group mb-3">
                                    <input className="form-control" value={this.state.mqttId} onChange={this.onChange}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" onClick={() => this.onResetId()}>Reset</button>
                                        <button className="btn btn-primary" onClick={() => this.onSaveId()}>Save</button>
                                    </div>
                                </div>
                            </td> 
                            :
                            <td>{value}</td> 
                        ) */
                    }
                </tr>
            );
        });
        return array;
    }

    onShowMQTT = () => {
        Services.CheckPassword(this.state.password).then(res => {
            if(res)
                this.getMqttDetails();
            //this.setState({mqttShow: res});
        }).catch(err => {
            console.log(err)
        })
    }

    onInputKeyDown = (e) => {
        if(e.key === "Enter")
            this.onShowMQTT();
    }

    getTopicPath = () => {
        let path = this.state.mqtt.id + "/";
    
        //substitute x for ids
        let split_topic_path = this.state.selectTopic.topic ? this.state.selectTopic.topic.split("/") : [];
        //let array_ids = this.state.selectDeviceID.ids;

        //let index_id = 0;
        split_topic_path.forEach(part => {
            if(part !== "x" )//|| array_ids === undefined)
                path += part + "/";
            else{
                path += this.state.selectDeviceID.device_id + "/"; //array_ids[index_id] + "/";
                //index_id++;
            }
        })

        path += this.state.selectProperty.text ? this.state.selectProperty.text : "";

        let type = "";
        if(this.state.selectProperty.type === "Double")
            type = "_d";
        else if(this.state.selectProperty.type === "String")
            type = "_s";
        else if(this.state.selectProperty.type === "Integer")
            type = "_i";
        else if(this.state.selectProperty.type === "Bool" || this.state.selectProperty.type === "Boolean")
            type = "_b";
        else
            type = "_o";

        return '"' + path + type + '"';
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">MQTT data</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="passwordShow" onKeyDown={this.onInputKeyDown} onChange={(e) => this.setState({password: e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="button" id="passwordShow" onClick={this.onShowMQTT}>Show MQTT data</button>
                            </div>
                        </div>
                        <table className="table table-striped">
                            <tbody>
                                {this.state.mqttShow ? this.getDetails(this.state.mqtt, ["IP", "Port", "Username", "Password"],["ip", "port", "username", "password"]) : null }
                            </tbody>
                        </table>
                        {this.state.mqttShow 
                        ?
                            <div>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-pub-tab" data-toggle="tab" href="#nav-pub" role="tab" aria-controls="nav-pub" aria-selected="true" onClick={() => {
                                            this.setState({activeTab: "pub"})
                                        }}>Publish</a>
                                        <a className="nav-item nav-link" id="nav-sub-tab" data-toggle="tab" href="#nav-sub" role="tab" aria-controls="nav-sub" aria-selected="false" onClick={() => {
                                            this.setState({activeTab: "sub"})
                                        }}>Subscribe</a>
                                    </div>
                                </nav>
                                <br/>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-pub" role="tabpanel" aria-labelledby="nav-pub-tab">
                                        <div>Template</div>
                                        <div>
                                            <code>mosquitto_pub -h {this.state.mqtt.ip} -p {this.state.mqtt.port} -u {this.state.mqtt.username} -P {this.state.mqtt.password} -t TOPIC -m MESSAGE</code>
                                        </div>
                                        {/*
                                        <div>Example</div>
                                        <code>mosquitto_pub -h {this.state.mqtt.ip} -p {this.state.mqtt.port} -u {this.state.mqtt.username} -P {this.state.mqtt.password} -t "room/2/temp" -m 10</code> 
                                        <br/>
                                        */}
                                        <br/>
                                        <div>
                                            <h6>Generator</h6>
                                        </div>
                                        <div>
                                            <span style={{verticalAlign: "bottom"}}>
                                                {this.state.mqtt.username} 
                                            </span>
                                            <span style={{verticalAlign: "bottom", marginLeft: 20, marginRight: 20}}>
                                                / 
                                            </span>
                                            <FormControl style={{minWidth: 120}}>
                                                <InputLabel htmlFor="select_topic">Topic</InputLabel>
                                                <Select
                                                    id="select_topic"
                                                    value={this.state.selectTopic}
                                                    onChange={(e) => this.setState({selectTopic: e.target.value, selectDeviceID: "", selectProperty: ""})}
                                                >
                                                    {this.state.topicsList.map((topic) => (
                                                        <MenuItem value={topic} key={topic._id}>{topic.topic}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <span style={{verticalAlign: "bottom", marginLeft: 20, marginRight: 20}}>
                                                / 
                                            </span>
                                            <FormControl style={{minWidth: 120}}>
                                                <InputLabel htmlFor="select_deviceID">DeviceID</InputLabel>
                                                <Select
                                                    id="select_deviceID"
                                                    value={this.state.selectDeviceID}
                                                    onChange={(e) => this.setState({selectDeviceID: e.target.value})}
                                                >
                                                    {this.state.deviceList.map((device) => {
                                                        if(this.state.selectTopic._id === device.topic._id)
                                                            return (<MenuItem value={device} key={device._id}>{device.device_id + " ("+ device.title +")"}</MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <span style={{verticalAlign: "bottom", marginLeft: 20, marginRight: 20}}>
                                                / 
                                            </span>
                                            <FormControl style={{minWidth: 120}}>
                                                <InputLabel htmlFor="select_property">Property</InputLabel>
                                                <Select
                                                    id="select_property"
                                                    value={this.state.selectProperty}
                                                    onChange={(e) => this.setState({selectProperty: e.target.value})}
                                                >
                                                    {this.state.selectTopic.properties ? this.state.selectTopic.properties.map((property, index) => (
                                                        <MenuItem value={property} key={"property_" + property + "_" + index}>{property.text}</MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        { this.state.selectTopic.topic && this.state.selectDeviceID.device_id && this.state.selectProperty.text ?
                                        <div>
                                            <code>
                                                mosquitto_pub 
                                                -h {this.state.mqtt.ip + " "} 
                                                -p {this.state.mqtt.port + " "} 
                                                -u {this.state.mqtt.username + " "} 
                                                -P {this.state.mqtt.password + " "} 
                                                -t {this.getTopicPath() + " "} 
                                                -m MESSAGE{this.state.selectProperty.type ? "_" + this.state.selectProperty.type : ""}
                                            </code>
                                        </div>
                                        : 
                                        null}
                                        {this.state.selectProperty.type === "Double" ? "Double values use '.' as a delimiter. No other delimiters are allowed inside. If there isn't any delimiter is the same as there would be .0 on the end of the value." : ""}
                                    </div>
                                    <div className="tab-pane fade" id="nav-sub" role="tabpanel" aria-labelledby="nav-sub-tab">
                                        <div>Template</div>
                                        <code>mosquitto_sub -h {this.state.mqtt.ip} -p {this.state.mqtt.port} -u {this.state.mqtt.username} -P {this.state.mqtt.password} -t TOPIC</code>
                                        {/*
                                        <div>Example</div>
                                        <code>mosquitto_sub -h {this.state.mqtt.ip} -p {this.state.mqtt.port} -u {this.state.mqtt.username} -P {this.state.mqtt.password} -t "room/2/temp"</code>
                                        <br/>
                                        */}
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MQTT;