import {store} from '../index';
import {getUser} from '../redux/actions'
//import * as Services from '../utility/services';

const UserTypes = require('../utility/userTypes.json');

export async function isUserLogged() {
    try {
        store.dispatch(getUser()).catch(err => {});
        /*
        console.log("Check");
        if(store.getState().user.username === null){
            console.log("No set");
            let user = await Services.GetLogUser();
            console.log("User", user);
            console.log(user ? true : false);
            if(user){
                store.dispatch(getUser());
                console.log("Good");
                return true;
            }
            else{
                console.log("Bad");
                return false;
            }
        }
        else {
            console.log("Already in store");
            return store.getState().user.username != null;
        }*/
    } catch (err) {
        return false;
    }
}

export function getUserType() {
    try {
        return store.getState().user.type;
    } catch (err) {
        return false;
    }
}

export function getUserFirstName() {
    try {
        return store.getState().user.firstName;
    } catch (err) {
        return false;
    }
}

export function getUserLastName() {
    try {
        return store.getState().user.lastName;
    } catch (err) {
        return false;
    }
}

export function getUsername() {
    try {
        return store.getState().user.username;
    } catch (err) {
        return false;
    }
}

export function isAccepted() {
    try {
        if(store.getState().user.type === UserTypes.admin || store.getState().user.type === UserTypes.super_admin)
            return 1;
        return store.getState().user.accepted;
    } catch (err) {
        return false;
    }
}




