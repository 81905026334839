export const checkIfEmpty = (object) => {
    let empty_keys = []

    for(let key in object) {
        if(Object.prototype.hasOwnProperty.call(object, key)){
            let value = object[key];
            if(value === "" && key != "radius"){
                empty_keys.push(key)
                //return true;
            }
        }
    }
    return empty_keys;
    //return false;
}

export const getLocalISOString = (date) => {
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1) + "").padStart(2, '0');
    let day = (date.getDate() + "").padStart(2, '0');
    let h = (date.getHours() + "").padStart(2, '0');
    let min = (date.getMinutes() + "").padStart(2, '0');
    return `${year}-${month}-${day}T${h}:${min}`;
}

export const breakUniqueMongoDBError = (data) => {
    let msg = "";
    if(data.indexOf("E11000")){
        let index = data.indexOf("index:");
        let substring = data.substring(index + 6);
        let split = substring.split(' ');
        for(let i=0; i<split.length; i++){
            if(split[i] !== ""){
                msg += split[i].split('_')[0];
                break;
            }
        }
        msg += " " + split[split.length - 2] + " already exists";

        msg = msg.charAt(0).toUpperCase() + msg.slice(1) + ".";
    }
    return msg;
}

export const getPropertyWithType = (property) => {
    let type = "";
    if(property.type === "Double")
        type = "_d";
    else if(property.type === "String")
        type = "_s";
    else if(property.type === "Integer")
        type = "_i";
    else if(property.type === "Bool" || property.type === "Boolean")
        type = "_b";
    else
        type = "_o";

    return property.text + type;
}

export const groupBy = function (arr, criteria) {
	return arr.reduce(function (obj, item) {
		var key = typeof criteria === 'function' ? criteria(item) : item[criteria];
		if (!obj.hasOwnProperty(key)) {
			obj[key] = [];
		}
		obj[key].push(item);
		return obj;
	}, {});
};
