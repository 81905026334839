import React from 'react';
import * as Services from '../utility/services';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import LinearProgress from '@material-ui/core/LinearProgress';
import {groupBy} from '../utility/functions'

export default class Topics extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            topicModal: null,
            topics: [],
            loading: false
        }

        this.columns = [{
            dataField: 'topic', 
            text: 'Topic',
            filter: textFilter()
          },{
            dataField: 'description', 
            text: 'Description', 
            filter: textFilter()
          },{
            dataField: 'properties', 
            text: 'Properties', 
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        {value.map(x => <div key={x.text + "_" + x.type + "_" + index}>{x.text} ({x.type}{x.timestamp ? " + ts" : ""})</div>)}
                    </div>
                )
            }
          },{
            dataField: 'type',
            text: 'Type'
          }
        ];
    }

    componentDidMount() {
        this.refresh();
    }

    requestTopic = () => {
        this.props.history.push({
            pathname: '/register_topics'
        });
    }

    refresh = () => {
        this.setState({loading: true});
        Services.GetTopicsCompaniesAllDetails().then(res => {
            let group = groupBy(res, x => x.topic._id)
            let topics = []
            for(let key in group){
                let topic = group[key][0].topic
                if(group[key].length == 2){
                    topic.type = "publish and subscribe"
                    topics.push(topic)
                }
                else {
                    topic.type = group[key][0].type
                    topics.push(topic)
                }
            }
            this.setState({topics: topics, loading: false});
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        })
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Topics</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-primary mr-1" onClick={this.requestTopic}>Request Topic</button>
                            <button type="button" className="btn btn-secondary mr-1" onClick={this.refresh}>Refresh</button>
                        </div>
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <BootstrapTable 
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.topics} 
                            columns={ this.columns } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                    </div>
                </div>
            </div>
        );
    }
}