import React from 'react';
import * as Services from '../../utility/services';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';

const inicial_state = {
    objectTopic: null,
    topic: "",
    level: 0,
    description: "",
    tags: [],
    properties: [{text: "", type: "String", timestamp: false}],
    tagValue: "",
    color: "",
    faClass: ""
}

export default class AddEditTopic extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            type: this.props.type,
            ...inicial_state
        }
    }

    componentDidUpdate(){
        //problem when going from edit to add device - form is not empty
        if(this.props.type !== this.state.type){
            this.setState({...inicial_state, type: this.props.type});
            //this.props.setMarkerLocation("","");
        }
    }

    componentDidMount() {
        if(this.props.type === "edit"){
            if(this.props.location.state){
                if(this.props.location.state.topicId){
                    let topicId = this.props.location.state.topicId;
                    Services.GetTopicById(topicId).then(topic => {
                        this.setState({objectTopic: topic, topic: topic.topic, level: topic.level, description: topic.description, tags: topic.tags, properties: topic.properties}); 
                        if(topic.mapMarkerStyle !== undefined){
                            this.setState({color: topic.mapMarkerStyle.color, faClass: topic.mapMarkerStyle.faClass});
                        }
                    }).catch(err => {});     
                }
            }
            else{
                this.props.history.push("/topics");
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let properties = this.state.properties;
        properties = properties.filter(x => x.text !== "");

        let topic = {
            topic: this.state.topic,
            level: this.state.level,
            description: this.state.description,
            tags: this.state.tags,
            properties: properties,
            mapMarkerStyle: { color: this.state.color, faClass: this.state.faClass},
            status: 'active'
        };

        if(this.state.topic === "" && this.state.level === "" && this.state.description === "")
            alert("Fill out all the fields");
        else if(properties.length === 0)
            alert("No properties");
        else{
            if(this.props.type === "add"){
                Services.AddTopic(topic).then(res => {
                    alert("Topic added")
                }).catch(err => {
                    alert("Something went wrong (probably topic already exists)");
                    console.log(err);
                });
            }
            else if(this.props.type === "edit"){
                Services.EditTopic(this.state.objectTopic._id, topic).then(res => alert("Saved changes"));
            }
        }
            
    }

    onNewProperty = () => {
        this.setState({properties: [{text: "", type: "", timestamp: false}, ...this.state.properties]});
    }

    onSelectTypeChange = (index, type) => {
        let properties = this.state.properties;
        properties[index].type = type;
        this.setState({properties});
    }

    onSelectCheckboxChange = (index, value) => {
        let properties = this.state.properties;
        properties[index].timestamp = value;
        this.setState({properties});
    }

    onInputPropChange = (index, text) => {
        let properties = this.state.properties;
        properties[index].text = text;
        this.setState({properties});
    }

    onDeleteProp = (index) => {
        let properties = this.state.properties;
        properties.splice(index, 1);
        this.setState({properties});
    }

    setTag = (index) => {
        let tags = this.state.tags;
        tags[index].value = !tags[index].value;
        this.setState({tags});
    }

    onKeyTag = (e) => {
        if(e.key === "Backspace" && e.target.value === ""){
            let tags = [...this.state.tags];
            tags.pop();
            this.setState({tags: tags});
        }
        else if(e.key === "Enter" && e.target.value !== "")
            this.setState({tags: [...this.state.tags, e.target.value], tagValue: ""});
    }

    onDeleteTag = (index) => {
        let tags = [...this.state.tags];
        tags.splice(index, 1);
        this.setState({tags: tags});
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">{this.props.type === "add" ? "Add" : "Edit"} topic</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group">
                            <label htmlFor="inputTopic">Topic</label>
                            <input className="form-control" type="text" id="inputTopic" value={this.state.topic} onChange={(e) => this.setState({topic: e.target.value})}></input>
                        </div>
                        {/*<div className="form-group">
                            <label htmlFor="inputLevel">Level</label>
                            <input className="form-control" type="number" id="inputLevel" value={this.state.level} onChange={(e) => this.setState({level: e.target.value})}></input>
                        </div>*/}
                        <div className="form-group">
                            <label htmlFor="inputDescription">Description</label>
                            <textarea className="form-control" type="text" id="inputDescription" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})}></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputTags">Tags</label>
                            <div>
                                {this.state.tags.map((tag, index) => (
                                    <Chip key={"tag_" + tag + "_" + index} label={tag} onDelete={() => this.onDeleteTag(index)} />
                                ))}
                                <span> </span>
                                <Input value={this.state.tagValue} onChange={(e) => this.setState({tagValue: e.target.value})} onKeyDown={(e) => this.onKeyTag(e)}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Map marker style (optional)</label>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="inputColor">Color</label>
                                    <input className="form-control" type="text" id="inputColor" value={this.state.color} placeholder="rgb(r,g,b) or #rrggbb" onChange={(e) => this.setState({color: e.target.value})}></input>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputFAClass">Font-awesome class</label>
                                    <input className="form-control" type="text" id="inputFAClass" value={this.state.faClass} onChange={(e) => this.setState({faClass: e.target.value})}></input>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" >
                            <label htmlFor="inputCompany">Properties</label>
                            <div className="float-right form-group">
                                <button type="button" className="btn btn-primary" onClick={this.onNewProperty}>Add property</button>
                            </div>
                            {this.state.properties.map((property, index) => (
                                <div className="input-group mb-3" key={index}>
                                    <input type="text" className="form-control" placeholder="Property" value={this.state.properties[index].text} onChange={(e) => this.onInputPropChange(index, e.target.value)} aria-label="Property" aria-describedby="button-addon2" />
                                    <div className="input-group-append">
                                        <select className="form-control" value={this.state.properties[index].type} id="inputCompany" onChange={(e) => this.onSelectTypeChange(index, e.target.value)}>
                                            <option>String</option>
                                            <option>Integer</option>
                                            <option>Double</option>
                                            <option>Boolean</option>
                                        </select>
                                        <div className="input-group-text">
                                            <input type="checkbox" id="readingTimestamp" value={this.state.properties[index].timestamp} checked={this.state.properties[index].timestamp} onChange={(e) => this.onSelectCheckboxChange(index, e.target.checked)}/>
                                            <label className="form-check-label" style={{marginLeft: 5}} htmlFor="readingTimestamp">
                                                Timestamp
                                            </label>
                                        </div>
                                        <button className="btn btn-outline-danger" type="button" id="button-addon2" onClick={() => this.onDeleteProp(index)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>{this.props.type === "add" ? "Add Topic" : "Save changes" }</button>
                    </div>
                </div>
            </div>
        );
    }
}