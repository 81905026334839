import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import { allReducers } from './redux/reducers'
import { Provider } from 'react-redux';
import promise from 'redux-promise-middleware';
import { initialize, LocalizeProvider } from 'react-localize-redux';
import { waitForConfig } from './config/config';

//const languages = ['sl', 'en'];

export const store = createStore(
    allReducers,
    {},
    compose(
        applyMiddleware(promise),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);
store.dispatch(initialize(['sl', 'en']));


if(process.env.NODE_ENV === 'production')
{
    waitForConfig.then(() => {
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>, 
            document.getElementById('root')
        );
    
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    })
}
else {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>, 
        document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}


