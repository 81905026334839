import React from 'react';

export default class NotAuthorzied extends React.Component {
    render() {
        return (
            <div className="App">
                <h1>Not authorized</h1>
            </div>
        );
    }
}