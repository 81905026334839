import React from 'react';
import * as Services from '../utility/services';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LinearProgress from '@material-ui/core/LinearProgress';

import {groupBy} from '../utility/functions'

class Modal extends React.Component {
    onService = (type) => {
        let newRequest = {
            topic: this.props.topic._id,
            type: type
        }
        Services.AddPendingPermission(newRequest).then(res => {
            alert(res.data.msg)
            let saved = res.data.saved
            saved.topic = this.props.topic
            this.props.addReqest(saved)
        }).catch(err => {
            console.log(err)
        })
    }

    onRequest = () => {
        let option = this.select.value
        if(option == "both"){
            this.onService("publish")
            this.onService("subscribe")
        }
        else
            this.onService(option)
    }

    onDelete = (id) => {
        Services.DeletePendingPermission(id).then(res => {
            alert(res.data.msg)
            this.props.removeReqest(this.props.topic._id, id)
        }).catch(err => {
            console.log(err)
            alert("Something went wrong")
        })
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md"  role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.props.type == "request" ?
                            <div>
                                <div>
                                    {this.props.existing != undefined && `You already have permission for ${this.props.existing[0].type}.`}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="permisson">Choose additional permissions for topic {this.props.topic && this.props.topic.topic}: </label>
                                    <select ref={x => this.select = x} className="form-control" id="permisson">
                                        {this.props.existing == undefined && <option key={"publish and subscribe"} value={"both"}>publish and subscribe</option>}
                                        {(this.props.existing == undefined || (this.props.existing != undefined && this.props.existing[0].type != "publish")) && <option key={"publish"} value={"publish"}>publish</option>}
                                        {(this.props.existing == undefined || (this.props.existing != undefined && this.props.existing[0].type != "subscribe")) && <option key={"subscribe"} value={"subscribe"}>subscribe</option>}
                                    </select>
                                </div> 
                            </div>
                            : 
                                this.props.existing && this.props.existing.map(x => {
                                    if(x.pending == true)
                                        return (
                                            <div key={x.type + x.topic._id} style={{display: 'flex', justifyContent: 'space-between'}}>{x.type} <button className="btn btn-danger" data-dismiss="modal" onClick={() => this.onDelete(x._id)}>Delete</button></div>
                                        )
                                })
                            }
                        </div>
                        <div className="modal-footer">
                            {this.props.type == "request" && 
                            <button type="button" className="btn btn-primary" onClick={this.onRequest} data-dismiss="modal">Request</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default class RegisterTopics extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            requestTopic: null,
            topics: [],
            loading: false,
            existingPermisions: undefined,
            requestExisting: null
        }
    }

    getColoums = (permisions) => {
        return [{
            dataField: 'topic', 
            text: 'Topic',
            filter: textFilter()
          },{
            dataField: 'description', 
            text: 'Description', 
            filter: textFilter()
          },{
            dataField: 'properties', 
            text: 'Properties', 
            formatter: (value, row, index, field) => {
                return (
                    <div>
                        {value.map(x => <div key={x.text + "_" + x.type + "_" + index}>{x.text} ({x.type}{x.timestamp ? " + ts" : ""})</div>)}
                    </div>
                )
            }
          },{
            dataField: 'status',
            text: 'Status',
            formatter: (value, row, index, field) => {
                if(!row.pending)
                    return row.status
                else
                    return "pending"
            }
          },{
            dataField: 'actions',
            text: 'Actions',
            style: {
                width: "1px",
                whiteSpace: "nowrap"
            },
            isDummyField: true,
            formatExtraData: permisions,
            formatter: (value, row, index, permisions) => {
                let code = []
                if(row.pending == undefined && permisions && !(permisions[row._id] != undefined && permisions[row._id].length == 2)){
                    code.push(
                        <div key={row._id + "_request"}>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalRequest" onClick={() => this.onRequest(row, permisions[row._id])}>Request</button>
                        </div>
                    )
                }
                if(permisions && permisions[row._id] && permisions[row._id].find(x => x.pending == true)){
                    code.push (
                        <div key={row._id + "_pending"}>
                            <button type="button" className="btn btn-warning mt-1" data-toggle="modal" data-target="#modalPending" onClick={() => this.onRequest(row, permisions[row._id])}>Pending</button>
                        </div>
                    )
                }
                return code
            }
          }
        ];
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        this.getAllTopics();
        this.getExistingPermissions();
    }

    getAllTopics = () => {
        this.setState({loading: true});
        Services.GetTopics().then(res => {
            this.setState({topics: res, loading: false});
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});
        })
    }

    getExistingPermissions = async () => {
        let result = []
        await Services.GetTopicsCompaniesAllDetails().then(res => {
            result = [...res, ...result]
        }).catch(err => {
            console.log(err);
        })

        await Services.GetCompanyPendingPermission().then(res => {
            res.map(x => {
                x.pending = true
                return x
            })
            result = [...res, ...result]
        }).catch(err => {
            console.log(err);
        })

        let group = groupBy(result, x => x.topic._id)
        this.setState({existingPermisions: group});
    }

    onRequest = (topic, permisions) => {
        this.setState({requestTopic: topic, requestExisting: permisions})
    }

    requestNewTopic = () => {
        this.props.history.push({
            pathname: '/request_topic'
        });
    }

    addReqest = (data) => {
        data.pending = true
        let existing = {}
        for(let key in this.state.existingPermisions){
            existing[key] = [...this.state.existingPermisions[key]]
        }
        if(existing[data.topic._id])
            existing[data.topic._id].push(data)
        else
        existing[data.topic._id] = [data]
        this.setState({existingPermisions: existing})
    }

    removeReqest = (topicId, pendingId) => {
        let existing = {}
        for(let key in this.state.existingPermisions){
            existing[key] = [...this.state.existingPermisions[key]]
        }
        existing[topicId] = existing[topicId].filter(x => x._id != pendingId)
        this.setState({existingPermisions: existing})
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Register topics</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="my-flex-end form-group">
                            <button type="button" className="btn btn-secondary" onClick={this.requestNewTopic}>Topic you need is not on the list? Request new topic</button>
                        </div>
                        {this.state.loading 
                        ? 
                            <div>
                                <LinearProgress />
                            </div> 
                        :
                            null
                        }
                        <BootstrapTable 
                            ref={x => this.table = x}
                            printable 
                            hover 
                            keyField='_id' 
                            data={this.state.topics} 
                            columns={ this.getColoums(this.state.existingPermisions) } 
                            pagination={ paginationFactory() }
                            filter={ filterFactory() }
                            noDataIndication={ this.state.loading ? null : "Empty" }
                        /> 
                    </div>
                </div>
                <Modal id={"modalRequest"} type="request" title={"Request"} topic={this.state.requestTopic} existing={this.state.requestExisting} refresh={this.refresh} addReqest={this.addReqest}/>
                <Modal id={"modalPending"} type="pending" title={"Pending"} topic={this.state.requestTopic} existing={this.state.requestExisting} refresh={this.refresh} removeReqest={this.removeReqest}/>
            </div>
        );
    }
}