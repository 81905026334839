const HOST = "http://localhost:5000"; //(process.env.NODE_ENV === 'production') ? process.env.HOST_ENV : "http://localhost:5000";

export let Config = {
    "server": {
        "url_users": HOST + "/b-users",
        "url_users_sign_up": HOST + "/b-users/sign_up",
        "url_users_login": HOST + "/b-users/login",
        "url_users_logout": HOST + "/b-users/logout",
        "url_users_user": HOST + "/b-users/user",
        "url_users_pending": HOST + "/b-users/pending",
        "url_users_change_password": HOST + "/b-users/change_password",
        "url_users_change_details": HOST + "/b-users/change_details",
        
        "url_topics": HOST + "/b-topics",
        "url_topics_add": HOST + "/b-topics/add",

        "url_companies": HOST + "/b-companies",
        "url_companies_add": HOST + "/b-companies/add",
        "url_companies_get": HOST + "/b-companies/get",

        "url_topic_company": HOST + "/b-topic_company",
        "url_topic_company_add": HOST + "/b-topic_company/add",
        "url_topic_company_remove": HOST + "/b-topic_company/remove",

        "url_devices": HOST + "/b-devices",
        "url_devices_add": HOST + "/b-devices/add",
        "url_devices_get_topic_company": HOST + "/b-devices/get_topic_company",

        "url_mail": HOST + "/b-mail",

        "url_admin_users": HOST + "/b-admin_users",

        "url_logs": HOST + "/b-logs",
        
        "url_msgs": HOST + "/b-msgs",

        "url_topic_company_user": HOST + "/b-topic_company_user",

        "url_pending_permission": HOST + "/b-pending_permissions"
    }
};

function makeConfig(HOST) {
    return {
        "server": {
            "url_users": HOST + "/b-users",
            "url_users_sign_up": HOST + "/b-users/sign_up",
            "url_users_login": HOST + "/b-users/login",
            "url_users_logout": HOST + "/b-users/logout",
            "url_users_user": HOST + "/b-users/user",
            "url_users_pending": HOST + "/b-users/pending",
            "url_users_change_password": HOST + "/b-users/change_password",
            "url_users_change_details": HOST + "/b-users/change_details",
            
            "url_topics": HOST + "/b-topics",
            "url_topics_add": HOST + "/b-topics/add",
    
            "url_companies": HOST + "/b-companies",
            "url_companies_add": HOST + "/b-companies/add",
            "url_companies_get": HOST + "/b-companies/get",
    
            "url_topic_company": HOST + "/b-topic_company",
            "url_topic_company_add": HOST + "/b-topic_company/add",
            "url_topic_company_remove": HOST + "/b-topic_company/remove",
    
            "url_devices": HOST + "/b-devices",
            "url_devices_add": HOST + "/b-devices/add",
            "url_devices_get_topic_company": HOST + "/b-devices/get_topic_company",
    
            "url_mail": HOST + "/b-mail",
    
            "url_admin_users": HOST + "/b-admin_users",
    
            "url_logs": HOST + "/b-logs",

            "url_msgs": HOST + "/b-msgs",

            "url_topic_company_user": HOST + "/b-topic_company_user",

            "url_pending_permission": HOST + "/b-pending_permissions"
        }
    }
}

export const waitForConfig = fetch('/config.json')
.then(response => response.json())
.then(response => Config = makeConfig(response.host))


/*
export let Config = {
    "server": {
        "url_users": HOST + "/b-users",
        "url_users_sign_up": HOST + "/b-users/sign_up",
        "url_users_login": HOST + "/b-users/login",
        "url_users_logout": HOST + "/b-users/logout",
        "url_users_user": HOST + "/b-users/user",
        "url_users_pending": HOST + "/b-users/pending",
        "url_users_change_password": HOST + "/b-users/change_password",
        "url_users_change_details": HOST + "/b-users/change_details",
        
        "url_topics": HOST + "/b-topics",
        "url_topics_add": HOST + "/b-topics/add",

        "url_companies": HOST + "/b-companies",
        "url_companies_add": HOST + "/b-companies/add",
        "url_companies_get": HOST + "/b-companies/get",

        "url_topic_company": HOST + "/b-topic_company",
        "url_topic_company_add": HOST + "/b-topic_company/add",
        "url_topic_company_remove": HOST + "/b-topic_company/remove",

        "url_devices": HOST + "/b-devices",
        "url_devices_add": HOST + "/b-devices/add",
        "url_devices_get_topic_company": HOST + "/b-devices/get_topic_company",

        "url_mail": HOST + "/b-mail",

        "url_admin_users": HOST + "/b-admin_users",

        "url_logs": HOST + "/b-logs"
    }
}*/