import React from 'react';
import '../App.css';
import Map from "../components/Map";
import { connect } from 'react-redux';
import {setMarkerLocation, setMarkerLatitude, setMarkerLongitude, setMarkerRadius} from '../redux/actions';
import "../../node_modules/bootstrap/dist/js/bootstrap";
import * as Services from '../utility/services';
import {checkIfEmpty, getLocalISOString} from '../utility/functions';
import {gdprDevice} from '../assets/text';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';

const ID = "x";
const tags = ["TG", "ID", "T", "D", "G", "L", "Time"];

const inicial_state = {
    id: "", 
    title: "", 
    description: "", 
    street: "", 
    city: "", 
    country: "", 
    start: new Date().toISOString().substring(0, 16), 
    end: null, 
    loading: false,
    gdprCheck: false,
    tags: []
}

class AddEditDevice extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ...inicial_state,
            type: this.props.type,
            topics: [],
            company: null,
            value: undefined,
            deviceID: undefined
        }
    }

    componentDidUpdate(){
        //problem when going from edit to add device - form is not empty
        if(this.props.type === "add" && this.props.type !== this.state.type){
            this.setState({...inicial_state, type: this.props.type});
            this.props.setMarkerLocation("","");
        }
    }

    componentDidMount() {
        Services.GetTopicsCompaniesPublish().then(res => {
            if(res.length){
                let array = res.map(x => x.topic);
                //this.setState({topics: array, value: 0, company: res[0].company});
                if(this.props.type === "edit")
                    this.setState({topics: array, company: res[0].company});
                else
                    this.setState({topics: array, value: 0, company: res[0].company});
            }
        });

        if(this.props.type === "add"){
            this.props.setMarkerLocation("","");
        }
        else if(this.props.type === "edit"){
            if(this.props.location.state){
                if(this.props.location.state.topic && this.props.location.state.device){
                    let topic = this.props.location.state.topic;
                    let device = this.props.location.state.device;
                    this.setState({
                        value: this.props.location.state.value,
                        id: device.device_id,
                        deviceID: device._id,
                        title: device.title,
                        description: device.description,
                        street: device.street,
                        city: device.city,
                        country: device.country,
                        start: getLocalISOString(new Date(device.start)),
                        end: device.end === null ? null : getLocalISOString(new Date(device.end)),
                        gdprCheck: true,
                        tags: device.tags
                    });
                    this.props.setMarkerLocation(device.latitude, device.longitude);
                }
            }
            else{
                this.props.history.push("/devices");
            }
        }
    }

    splitTopic = (topic) => {
        return topic.split('/');
    }

    /*onInputIDChange = (id, value) => {
        let index = id.split('_')[1];
        let arrayID = [...this.state.id];
        arrayID[index] = value;
        this.setState({id: arrayID});
    }*/

    getTopicIDInput = (index) => {
        let topic = this.state.topics[index];
        let arrayTopic = this.splitTopic(topic.topic);
        let code = [];
        let string = "";
        let indexID = 0;

        arrayTopic.forEach((element, index) => {
            if(element !== "x")
                string += element + "/";
            else if(element === "x"){
                code.push(
                    <div key={"div" + index} className="input-group-prepend">
                        <span className="input-group-text">{string}</span>
                    </div>
                );
                code.push( <input key={"input" + index} type="text" className="form-control" id={"inputID_"+indexID} aria-label="Device ID"  value={this.state.id} onChange={(e) => this.setState({id: e.target.value})}/> );
                string = "/";
                indexID++;
            }
        });

        if(string !== "/")
            code.push(
                <div key={arrayTopic.length} className="input-group-append">
                    <span className="input-group-text">{string.substring(0, string.length - 1)}</span>
                </div>
            );

        return code;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        let device = {
            topic: this.state.topics[this.state.value],
            company: this.state.company,
            device_id: this.state.id,
            title: this.state.title,
            description: this.state.description,
            latitude: this.props.markerLocation.latitude,
            longitude: this.props.markerLocation.longitude,
            radius: this.props.markerLocation.radius,
            street: this.state.street,
            city: this.state.city,
            country: this.state.country,
            start: new Date(this.state.start),
            end: this.state.end === null ? null : new Date(this.state.end),
            tags: this.state.tags
        }

        if(checkIfEmpty(device).length != 0){
            alert("Please fill out all the fields (" + checkIfEmpty(device).toString() + ")")
            this.setState({loading: false});
        }
        else if(this.state.gdprCheck === false){
            alert("Please check the field for data usage");
            this.setState({loading: false});
        }
        else{
            if(this.props.type === "add"){
                Services.AddDevice(device)
                .then(res => {
                    if(res){
                        alert("Device added");
                        this.setState({id: "", title: "", description: "", street: "", city: "", country: "", start: new Date().toISOString().substring(0, 16), end: null, loading: false, tags: []});
                        this.props.setMarkerLocation("","");
                    }
                    else{
                        alert("Something went wrong");
                        this.setState({loading: false});
                    }
                })
                .catch(err => {
                    if(err.response.data)
                        alert(err.response.data)
                    else
                        alert("Something went wrong");
                    this.setState({loading: false});
                })
            }
            else if(this.props.type === "edit"){
                Services.EditDevice(this.state.deviceID, device)
                .then(res => {
                    if(res){
                        alert("Device edited");
                        this.props.history.push('/devices');
                    }
                    else{
                        alert("Something went wrong");
                        this.setState({loading: false});
                    }
                })
                .catch(err => {
                    if(err.response.data)
                        alert(err.response.data)
                    else
                        alert("Something went wrong");
                    this.setState({loading: false});
                })
            }
        }
    }

    selectedTopic = (index) => {
        let value = this.state.topics[index];
        /*let topic = value.topic;
        let array = topic.toString().split('/');
        let num = 0;
        for(let i=0; i<array.length; i++)
            if(array[i] === ID)
                num++;*/

        if(this.props.type === "add")
            this.setState({value: index, id: ""});
        else
            this.setState({value: index});
    }

    setTag = (index) => {
        let tags = this.state.tags;
        tags[index].value = !tags[index].value;
        this.setState({tags});
    }

    onKeyTag = (e) => {
        if(e.key === "Backspace" && e.target.value === ""){
            let tags = [...this.state.tags];
            tags.pop();
            this.setState({tags: tags});
        }
        else if(e.target.value == "ID" || e.target.value == "id"){
            alert("Use of tag 'ID' is occupied for default group.")
            this.setState({tagValue: ""});
        }
        else if(e.key === "Enter" && e.target.value !== "")
            this.setState({tags: [...this.state.tags, e.target.value], tagValue: ""});
    }

    onDeleteTag = (index) => {
        let tags = [...this.state.tags];
        tags.splice(index, 1);
        this.setState({tags: tags});
    }

    getForm = (tags) => {
        let code = [];
        tags.forEach(tag => {
            switch (tag) {
                case "TG":
                    code.push(
                        <div className="form-group" key="Tags">
                            <Tooltip title="You can't use tag 'ID'. After each tag you need to press enter." placement="right">
                                <label htmlFor="inputTags">Tags (optional)<b>*</b></label>
                            </Tooltip>
                            
                            <div>
                                {this.state.tags.map((tag, index) => (
                                    <Chip key={"tag_" + tag + "_" + index} label={tag} onDelete={() => this.onDeleteTag(index)} />
                                ))}
                                <span> </span>
                                <Input value={this.state.tagValue} onChange={(e) => this.setState({tagValue: e.target.value})} onKeyDown={(e) => this.onKeyTag(e)}/>
                            </div>
                        </div>
                    );
                    break;
                case "ID":
                    code.push(
                        <div className="form-group" key="ID">
                            <label htmlFor="inputID">ID</label>
                            <div className="input-group mb-3">
                                {this.getTopicIDInput(this.state.value)}
                            </div>
                        </div>
                    );
                    break;
                case "T": 
                    code.push(
                        <div className="form-group" key="title">
                            <label htmlFor="inputTitle">Title</label>
                            <input className="form-control" type="text" id="inputTitle" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})}></input>
                        </div>
                    );
                    break;
                case "D": 
                    code.push(
                        <div className="form-group" key="description">
                            <label htmlFor="inputDescription">Description</label>
                            <textarea className="form-control" type="text" id="inputDescription" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})} ></textarea>
                        </div>
                    );
                    break;
                case "G": 
                    code.push(
                        <div key="geolocation">
                            <div className="row">
                                <div className="col-sm">
                                    <label htmlFor="inputLat">Latitude</label>
                                    <input className="form-control" type="number" id="inputLat" value={this.props.markerLocation.latitude} onChange={(e) => this.props.setMarkerLatitude(e.target.value)}></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputLgt">Longitude</label>
                                    <input className="form-control" type="number" id="inputLgt" value={this.props.markerLocation.longitude} onChange={(e) => this.props.setMarkerLongitude(e.target.value)}></input>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="inputLgt">Radius (optional)</label>
                                    <input className="form-control" type="number" id="inputRadius" value={this.props.markerLocation.radius} onChange={(e) => this.props.setMarkerRadius(e.target.value)}></input>
                                </div>
                            </div>
                            <Map latitude={46.554649} longitude={15.645881} zoom={14} topic={{id: this.state.topics[this.state.value]._id, mapMarkerStyle: this.state.topics[this.state.value].mapMarkerStyle}}/>
                        </div>
                    );
                    break;
                case "L": 
                    code.push(
                        <div key="location">
                            <div className="form-group">
                                <label htmlFor="inputStreetAddress">Street Address</label>
                                <input className="form-control" type="text" id="inputStreetAddress" value={this.state.street} onChange={(e) => this.setState({street: e.target.value})}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputCity">City</label>
                                <input className="form-control" type="text" id="inputCity" value={this.state.city} onChange={(e) => this.setState({city: e.target.value})}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputCountry">Country</label>
                                <input className="form-control" type="text" id="inputCountry" value={this.state.country} onChange={(e) => this.setState({country: e.target.value})}></input>
                            </div>
                        </div>
                    );
                    break;
                case "Time": 
                    code.push(
                        <div key="time" >
                            <div className="row">
                                <div className="col-sm form-group">
                                    <label htmlFor="datetimeStart">Start</label>
                                    <input className="form-control" type="datetime-local" min={getLocalISOString(new Date())}  value={this.state.start} id="datetimeStart" onChange={(e) => this.setState({start: e.target.value})}></input>
                                </div>
                                <div className="col-sm form-group">
                                    <label htmlFor="datetimeEnd">End</label>
                                    <input className="form-control" type="datetime-local" id="datetimeEnd" min={this.state.start} value={this.state.end ? this.state.end : ""} onChange={(e) => this.setState({end: e.target.value})}></input>
                                </div>
                            </div>
                        </div>
                    );
                    break;
                default:
                    break;
            }
        });

        //code.push(<button key={"submitButton"} type="submit" className="btn btn-primary btn-block">Add Device</button>);

        code.push(
            <div key={"gdpr"} className="form-check">
                <input key={"checkBoxGdprInput"} className="form-check-input" checked={this.state.gdprCheck} onChange={e => this.setState({gdprCheck: e.target.checked})} type="checkbox" id="gdpr"/>
                <label key={"checkBoxGdprLabel"} className="form-check-label" htmlFor="gdpr">
                    {gdprDevice}
                </label>
            </div>
        );

        code.push(
            <button key={"submitButton"} type="submit" className="btn btn-primary btn-block" disabled={this.state.loading} onClick={this.handleSubmit}>
                {this.state.loading && <span className="spinner-border spinner-border-sm spinnerStyle" role="status" aria-hidden="true"></span>}
                {this.props.type === "add" ? "Add Device" : "Save changes"}
            </button>
        );
        //let result = (<form>{code}</form>);
        return code;
    }

    render() {
        let form = this.state.topics.length ? this.getForm(tags) : null;
        return (
            <div style={{marginTop: "50px", marginBottom: "50px"}}>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h1 className="display-4">{this.props.type === "add" ? "Add" : "Edit" } Device</h1>
                    </div>
                </div>
                <div className="container">
                    <div>
                        <div className="form-group">
                            <label htmlFor="selectTopic">Select topic</label>
                            <select className="form-control" id="selectTopic" value={this.state.value} onChange={(e) => this.selectedTopic(e.target.value)}>
                                {this.state.topics.map((topic, index) => {
                                    return <option key={index} value={index}>{topic.topic}</option>
                                })}
                            </select>
                        </div>
                        {form}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    markerLocation: state.markerLocation
});

export default connect(mapStateToProps, { setMarkerLocation, setMarkerLatitude, setMarkerLongitude, setMarkerRadius })(AddEditDevice);