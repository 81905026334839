import React from 'react';
import * as Services from '../utility/services';
import "../../node_modules/bootstrap/dist/js/bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

let deviceExample = {
    //topic: "",
    //company: this.state.company,
    device_id: "",
    title: "",
    description: "",
    latitude: "",
    longitude: "",
    street: "",
    city: "",
    country: "",
    start: new Date(),
    end: null,
    radius: "",
    tags: []
}

const headerCellStyle = {
    fontWeight: 'bold',
    backgroundColor: "#eee",
}

export default class ImportDevices extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            inputFile: null,
            context: null,
            importType: null,
            template: null,
            preview: null,
            topics: [],
            selectedTopic: "",
            company: null,
            loading: false,
            importing: false,
            responses: null,
            rowsPerPage: 10,
            page: 0
        }
        this.reader = new FileReader();
        /*this.columns = [{
            dataField: 'ids',
            text: 'IDs'
          },{
            dataField: 'title',
            text: 'Title'
          }, {
            dataField: 'description',
            text: 'Description'
          },{
            dataField: 'latitude',
            text: 'Geolocation',
            formatter: (value, row, index, field) => {
                return (<div>{value}<br/>{row.longitude}</div>);
            }
          },{
            dataField: 'street',
            text: 'Address',
            formatter: (value, row, index, field) => {
                return value + ", " + row.city;
            }
          },{
            dataField: 'start',
            text: 'Start-End',
            formatter: (value, row, index, field) => {
                return (<div>{value}<br/>{row.end === null ? " --- " : row.end}</div>);
            }
          },{
            dataField: 'response',
            text: 'Response',
            formatter: (value, row, index, field) => {
                return this.state.responses[index];
            }
          }
        ];*/
    }

    componentDidMount() {
        this.reader.onload = (e) => {
            try {
                this.setState({context: JSON.parse(e.target.result), loading: false});
            }
            catch(err){
                this.setState({loading: false});
                alert(err)
            }
            
        }

        Services.GetTopicsCompaniesPublish().then(res => {
            if(res.length){
                let array = res.map(x => x.topic);
                this.setState({topics: array, company: res[0].company});
            }
        });
    }

    onLoadFile = () => {
        this.setState({loading: true})
        if(this.state.inputFile){
            if(this.state.inputFile.length){
                this.reader.readAsText(this.state.inputFile[0]);
            }
            else
                this.setState({loading: false})
        } 
        else
            this.setState({loading: false})
    }

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.files}); 
    }

    onImport = () => {
        if(this.state.selectedTopic !== ""){
            this.setState({importing: true})
            let responses = [];
            this.state.context.forEach((device, index) => {
                let newDevice = device;
                newDevice.company = this.state.company;
                newDevice.topic = this.state.topics[this.state.selectedTopic];
                Services.AddDevice(newDevice).then(res => {
                    responses[index] = "Added";
                    if(responses.length === this.state.context.length){
                        this.setState({responses: responses, importing: false})
                    }
                }).catch(err => {
                    responses[index] = JSON.stringify(err.response.data);
                    if(responses.length === this.state.context.length){
                        this.setState({responses: responses, importing: false})
                    }
                });
            });
        }
        else
            alert("Please select a topic");
        
    }

    selectedTopic = (index) => {
        let value = this.state.topics[index];
        this.setState({value, selectedTopic: index});
    }

    render(){
        return(
            <div>
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h1 className="display-4">Import</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="form-group">
                            <label htmlFor="selectTopic">Select topic</label>
                            <select className="form-control" id="selectTopic" value={this.state.selectedTopic} onChange={(e) => this.selectedTopic(e.target.value)}>
                                <option value={""}></option>
                                {this.state.topics.map((topic, index) => {
                                    return <option key={index} value={index}>{topic.topic}</option>
                                })}
                            </select>
                        </div>
                        <div>
                            {<pre><code>Example: <br/>[{JSON.stringify(deviceExample, null, 4)}]</code></pre>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFile">File input</label>
                            <input type="file" accept=".json" className="form-control-file" id="inputFile" onChange={this.onChange} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.loading} onClick={this.onLoadFile}>
                            {this.state.loading && <span className="spinner-border spinner-border-sm spinnerStyle" role="status" aria-hidden="true"></span>}
                            Load
                        </button>
                        { this.state.context
                            ?
                                <div>
                                    <hr/>
                                    <h3>Preview</h3>
                                    <button type="submit" className="btn btn-primary btn-block" disabled={this.state.importing} onClick={this.onImport}>
                                        {this.state.importing && <span className="spinner-border spinner-border-sm spinnerStyle" role="status" aria-hidden="true"></span>}
                                        Import
                                    </button>
                                    {/*<BootstrapTable 
                                        printable 
                                        hover 
                                        keyField={['id','title']}
                                        data={ this.state.context } 
                                        columns={ this.columns } 
                                        pagination={ paginationFactory() }
                                    /> */}
                                    <Paper style={{width: "100%", marginTop: 10}}>
                                        <TableContainer style={{maxHeight: 500}}>
                                            <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={headerCellStyle}>
                                                        ID
                                                    </TableCell>
                                                    <TableCell style={headerCellStyle}>
                                                        Title
                                                    </TableCell>
                                                    <TableCell style={headerCellStyle}>
                                                        Description
                                                    </TableCell>
                                                    <TableCell style={headerCellStyle}>
                                                        Address
                                                    </TableCell>
                                                    <TableCell style={headerCellStyle}>
                                                        Geolocation
                                                    </TableCell>
                                                    <TableCell style={headerCellStyle}>
                                                        Start-End
                                                    </TableCell>
                                                    {this.state.responses ?
                                                    <TableCell style={headerCellStyle}>
                                                        Response
                                                    </TableCell>
                                                    : null}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.context.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, index) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index + "_" + row.device_id}>
                                                        <TableCell>
                                                            {row.device_id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.street + ",\n" + row.city + ",\n" + row.country}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.latitude + ",\n" + row.longitude}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.start + ",\n" + row.end}
                                                        </TableCell>
                                                        {this.state.responses ?
                                                        <TableCell style={headerCellStyle}>
                                                            {
                                                                this.state.responses[index]
                                                            }
                                                        </TableCell>
                                                        : null}
                                                    </TableRow>
                                                );
                                                })}
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={this.state.context.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={(e, newPage) => {
                                                this.setState({page: newPage})
                                            }}
                                            onChangeRowsPerPage={e => {
                                                this.setState({rowsPerPage: +e.target.value, page: 0})
                                            }}
                                        />
                                    </Paper>
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}